import { is, OrderedSet, ValueObject } from 'immutable';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { ChatMessageDTO } from '../../../../../api/chat/chat.dto';

class ChatMessageSetItem implements ValueObject {
  constructor(public message: ChatMessageDTO) {}

  equals(other: unknown): boolean {
    if (other instanceof ChatMessageSetItem) {
      return this.message.id === other.message.id;
    }
    return is(this.message, other);
  }

  hashCode(): number {
    return this.message.id | 0;
  }
}

type State = {
  /**
   * Using OrderedSet to prevent duplicated messages to appear.
   */
  messages: OrderedSet<ChatMessageSetItem>;
};

function initialState({ initialMessages }: { initialMessages: ChatMessageDTO[] }): State {
  return {
    messages: OrderedSet(initialMessages.map((m) => new ChatMessageSetItem(m))),
  };
}

type Action =
  | {
      type: 'append';
      message: ChatMessageDTO;
    }
  | {
      type: 'reset';
      messages: ChatMessageDTO[];
    };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'append':
      return {
        messages: state.messages.add(new ChatMessageSetItem(action.message)),
      };
    case 'reset':
      return initialState({ initialMessages: action.messages });
  }
}

export function useConversationState({ initialMessages }: { initialMessages: ChatMessageDTO[] }) {
  const [state, dispatch] = useReducer(reducer, initialState({ initialMessages }));

  const appendMessage = useCallback(
    (message: ChatMessageDTO) => dispatch({ type: 'append', message }),
    [],
  );
  const resetMessages = useCallback(
    (messages: ChatMessageDTO[]) => dispatch({ type: 'reset', messages }),
    [],
  );
  const messages = useMemo(() => state.messages.toArray().map((i) => i.message), [state.messages]);

  useEffect(() => {
    resetMessages(initialMessages);
  }, [initialMessages]);

  return {
    appendMessage,
    resetMessages,
    messages,
  };
}
