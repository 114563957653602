import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

const backdrop = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const modal = {
  hidden: { y: '-100vh', opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { delay: 0.2 },
  },
};

type ModalProps = {
  showModal: boolean;
  children: React.ReactNode;
  onBackdropClick?: () => void;
};

export const AnimatedModal = ({ showModal, onBackdropClick, children }: ModalProps) => {
  return (
    <AnimatePresence>
      {showModal && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-50 z-[1010] flex-center"
          variants={backdrop}
          initial="hidden"
          animate="visible"
          exit="hidden"
          onMouseDown={onBackdropClick}
        >
          <div className="w-full h-full overflow-auto flex-center">
            <div className="max-h-full max-w-full">
              <motion.div
                className="mx-auto bg-white rounded text-center w-full sm:max-w-[600px]"
                variants={modal}
                onMouseDown={(e) => e.stopPropagation()}
              >
                {children}
              </motion.div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
