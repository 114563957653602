import { Badge } from 'antd';
import { BsFillChatSquareTextFill } from 'react-icons/bs';
import { useChatBoxState } from '../../../hooks/useChatBoxState';

const ChatBoxButton = ({ unreadCount }: { unreadCount?: number }) => {
  const { openChatBox } = useChatBoxState();
  return (
    <div
      onClick={openChatBox}
      className="ant-btn-primary border rounded-full w-full h-full bg-primary flex justify-center items-center cursor-pointer transition duration-300 hover:rotate-[17deg]"
    >
      <Badge showZero={false} offset={[-20, 0]} count={unreadCount ?? 0}>
        <BsFillChatSquareTextFill color="white" size={25} />
      </Badge>
    </div>
  );
};

export default ChatBoxButton;
