import { UsersConversationDTO } from '@/api/chat/chat.dto';
import { getProfilePhoto } from '@/utils/profilePhoto';
import { Badge } from 'antd';
import Avatar from 'antd/lib/avatar';
import cn from 'classnames';
import { FaChevronRight } from 'react-icons/fa';
const ConversationItem = ({
  conversationItem,
  onClick,
  archived,
}: {
  conversationItem: UsersConversationDTO;
  onClick: () => void;
  archived: boolean;
}) => {
  return (
    <div
      onClick={onClick}
      className={cn(
        'rounded-md border border-gray-f3 bg-gray-f9 hover:bg-gray-f3 px-1.5 py-2 cursor-pointer border-b shadow flex flex-row items-center',
        archived ? 'border-b-gray-500 text-gray-500' : 'border-b-primary',
      )}
    >
      <Avatar size="large" src={getProfilePhoto(conversationItem, 'medium')} alt="profilePhoto" />
      <div className="flex flex-col ml-5 w-full">
        <div className="text-sm">{conversationItem.userName}</div>
        <div className="text-xs">{conversationItem.conversationsCount} conversations</div>
      </div>
      <Badge showZero={false} offset={[-20, 0]} count={conversationItem.unreadCount ?? 0} />
      <FaChevronRight size={25} className={archived ? 'text-gray-500' : 'text-primary'} />
    </div>
  );
};

export default ConversationItem;
