import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { DataUpdateFunction, Updater } from 'react-query/types/core/utils';

export type ExtractMutationData<T> = T extends UseMutationResult<infer TData, any, any>
  ? TData
  : never;
export type ExtractMutationError<T> = T extends UseMutationResult<any, infer TError, any>
  ? TError
  : never;
export type ExtractMutationVariables<T> = T extends UseMutationResult<any, any, infer TVariables>
  ? TVariables
  : never;

/**
 * Extends mutation with some more async actions. Useful when you want your onSuccess handler to be async.
 */
export function useExtendMutation<
  TData,
  TError,
  TVariables,
  TDataOut = TData,
  TVariablesOut = TVariables,
>(
  mutation: UseMutationResult<TData, TError, TVariables>,
  {
    mapResult = async (result) => result as unknown as TDataOut, // Forceful conversion is fine because this function defines TDataOut. If it's undefined, then TDataOut will be just TData.
    mapVariables = (vars) => vars as unknown as TVariables, // Forceful conversion is fine because this function defines TVariablesOut. If it's undefined, then TVariablesOut will be just TVariables.
    options,
  }: {
    mapResult?: (result: TData) => Promise<TDataOut>;
    mapVariables?: (vars: TVariablesOut) => TVariables;
    options?: Omit<UseMutationOptions<TDataOut, unknown, TVariablesOut>, 'mutationFn'>;
  },
): UseMutationResult<TDataOut, unknown, TVariablesOut> {
  // we don't know what error `mapResult` can throw, so error is of unknown type.
  return useMutation<TDataOut, unknown, TVariablesOut>(async (vars, ...args) => {
    const mappedVars = mapVariables(vars);
    const result = await mutation.mutateAsync(mappedVars, ...args);

    return await mapResult(result);
  }, options);
}

export function isDataUpdateFunction<T, U>(
  updater: Updater<T, U>,
): updater is DataUpdateFunction<T, U> {
  return typeof updater === 'function';
}
