import { z } from 'zod';

export const ProfilePhotoVersion = z.enum(['small', 'medium', 'large', 'original']);
export type ProfilePhotoVersion = z.infer<typeof ProfilePhotoVersion>;

export type ProfilePhotoMetadata = {
  versions: Record<ProfilePhotoVersion, { url: string }>;
};
export type UserProfilePhotoFields = {
  profilePhoto: string | null;
  profilePhotoSmall: string | null;
  profilePhotoMedium: string | null;
  profilePhotoLarge: string | null;
};
