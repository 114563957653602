import { UsersListParams } from '@/api/user/user.api';
import { AdminUsersQueryParams } from '@/features/admin/users/UsersPage';
import { ShippingLabelQueryParams } from '@/features/shipping/shippingLabelPage/ShippingLabelPage';
import type { OfferDraftKey } from '@/features/trade/offerDrafts.store';
import { ShowcasePageTabKeys } from '@/features/user/pages/UserShowcasePage/UserShowcasePage';
import { TradeTypesEnum } from '@/pages/trade/[slug]';
import { StatusEnum } from '@/pages/trade/[slug]/[status]';
import { MyAuctionsPageTabs } from '@/pages/user/auctions/[status]';
import { CurrentBidsPageTabs } from '@/pages/user/current-bids/[tab]';
import { ProfilePageTabs } from '@/pages/user/profile/[tab]';
import { SettingsPageTabs } from '@/pages/user/settings/[tab]';
import { WatchlistPageTabs } from '@/pages/user/watchlist/[tab]';
import { NotificationsAPIParams } from '../api/notification/notification.api';
import { ShowcaseItemsAPIParams } from '../api/showcaseItems/showcaseItems.api';
import { ForgotPasswordQueryParams } from '../features/auth/pages/ForgotPasswordPage/ForgotPasswordPage';
import { ForgotPasswordVerifyQueryParams } from '../features/auth/pages/ForgotPasswordVerifyPage/ForgotPasswordVerifyPage';
import { LoginQueryParams } from '../features/auth/pages/LoginPage/LoginPage';
import { NewPasswordQueryParams } from '../features/auth/pages/NewPasswordPage/NewPasswordPage';
import { SetupAccountQueryParams } from '../features/auth/pages/SetupAccountPage/SetupAccountPage';
import { SignUpQueryParams } from '../features/auth/pages/SignUpPage/SignUpPage';
import { makeUrl } from '../utils/url';

type DonationPaymentId = NumberParam<`:donationPaymentId`>;
type TradeOfferId = NumberParam<`:tradeOfferId`>;
type ShippingId = NumberParam<`:shippingId`>;
type TradeId = NumberParam<`:tradeId`>;

export default {
  shipping: {
    directProve: (shippingId: ShippingId) => `/shipping/direct-prove/${shippingId}`,
    editDirectProve: (shippingId: ShippingId) => `/shipping/edit-direct-prove/${shippingId}`,
    shippingLabel: {
      root: (shippingId: ShippingId) => `/shipping/${shippingId}/shipping-label`,
      createShippingRates: (shippingId: ShippingId) =>
        `/shipping/${shippingId}/shipping-label/create-shipping-rates`,
      chooseShippingRate: (shippingId: ShippingId, params?: ShippingLabelQueryParams) =>
        makeUrl(`/shipping/${shippingId}/shipping-label/choose-shipping-rate`, params),
    },
    track: (shippingId: ShippingId) => `/shipping/track/${shippingId}`,
    finalizeDeal: (tradeOfferId: TradeOfferId) => `/shipping/finalize-deal/${tradeOfferId}`,
    tradeReviews: (tradeId: TradeId) => `/shipping/tradeReviews/${tradeId}`,
    reportIssue: (tradeOfferId: TradeOfferId) => `/shipping/report-issue/${tradeOfferId}`,
  },
  charityApplications: {
    current: `/charity-applications/current`, // TODO: implement
    apply: `/charity-applications/apply`, // TODO: implement
  },
  charity: {
    donation: {
      payment: ({ donationPaymentId }: { donationPaymentId: DonationPaymentId }) =>
        `/charity/donation/payment/${donationPaymentId}`,
    },
  },
  addItem: '/add-item',
  addAuctionItem: '/add-item?forAuction=true',
  addWantedItem: '/add-wanted-item',
  editItem: (showcaseItemId: NumberParam<':showcaseItemId'>) => `/edit-item/${showcaseItemId}`,
  editWantedItem: (wantedItemId: NumberParam<':wantedItemId'>) =>
    `/edit-wanted-item/${wantedItemId}`,
  userPaymentDetails: '/user/payment-details',
  user: (tab: EnumParam<ProfilePageTabs, ':tab'> = 'profile') => `/user/profile/${tab}`,
  settings: (tab: EnumParam<SettingsPageTabs, ':tab'> = 'notifications') => `/user/settings/${tab}`,
  myAuctions: (status: EnumParam<MyAuctionsPageTabs, ':status'> = 'scheduled') =>
    `/user/auctions/${status}`,
  myAuctionDetails: (showcaseItemId: number | '[showcaseItemId]') =>
    `/user/auctions/details/${showcaseItemId}`,
  currentBids: (tab: EnumParam<CurrentBidsPageTabs, ':tab'> = 'winning') =>
    `/user/current-bids/${tab}`,
  edit: '/user/profile/profile',
  home: '/',
  signUp: (params?: SignUpQueryParams) => makeUrl('/signUp', params),
  pricing: '/pricing',
  login: (params?: LoginQueryParams) => makeUrl('/login', params),
  forgotPassword: (params?: ForgotPasswordQueryParams) => makeUrl('/login/forgotPassword', params),
  verifyForgotPassword: (params?: ForgotPasswordVerifyQueryParams) =>
    makeUrl('/login/forgotPassword/verify', params),
  newPassword: (params?: NewPasswordQueryParams) => makeUrl('/login/newPassword', params),
  error: '/error',
  error404: '/page-not-found',
  about: '/about',
  terms: '/terms',
  shippoTracking: '/shippoTracking',
  setupAccount: (params?: SetupAccountQueryParams) => makeUrl('/setup-account', params),
  setupStripeAccount: '/setup-stripe-account',
  upgradeMembership: '/upgrade-membership',
  upgradeMembershipDone: '/upgrade-membership/done',
  connectedAccountError: '/connected-account/error',
  profile: (userId: NumberParam<':userId'>) => `/user/${userId}`,
  liveStream: ({
    userId,
    liveStreamId,
  }: {
    userId: NumberParam<':userId'>;
    liveStreamId?: NumberParam<':liveStreamId', '?'>;
  }) => `/user/${userId}/live/${liveStreamId ?? ''}`,
  liveAuction: ({
    userId,
    liveStreamId,
  }: {
    userId: NumberParam<':userId'>;
    liveStreamId?: NumberParam<':liveStreamId', '?'>;
  }) => `/user/${userId}/live-auction/${liveStreamId ?? ''}`,
  newLiveStream: {
    normal: '/live/new',
    auction: '/live-auction/new',
  },
  liveStreamHistory: (userId: NumberParam<':userId'>) => `/user/${userId}/live-history`,
  showcase: ({
    userId,
    tab,
    params,
  }: {
    userId: NumberParam<':userId'>;
    tab?: ShowcasePageTabKeys;
    params?: ShowcaseItemsAPIParams & { follow?: true };
  }) => makeUrl(`/user/${userId}/showcase/${tab ? tab : ''}`, params),
  ebay: {
    root: (userId: NumberParam<':userId'> | '[userId]') => makeUrl(`/user/${userId}/ebay`),
    edit: (userId: NumberParam<':userId'> | '[userId]', itemId: NumberParam<':id'> | '[id]') =>
      makeUrl(`/user/${userId}/ebay/edit/${itemId}`),
  },
  showcases: '/showcases',
  purchases: '/trade/purchases',
  cashOffers: '/trade/cash-offers',
  trades: '/trade/trades',
  offers: {
    list: '/trade/offers',
    root: '/trade',
    addTheirItems: (offerDraftKey: ':offerDraftKey' | OfferDraftKey) =>
      `/trade/${offerDraftKey}/add-their-items`,
    addOwnItems: (offerDraftKey: ':offerDraftKey' | OfferDraftKey) =>
      `/trade/${offerDraftKey}/add-my-items`,
    review: (params?: { isGuest?: boolean }) => makeUrl('/trade/new', params),
    counter: (offerDraftKey: NumberParam<':offerDraftKey'> | number) =>
      `/trade/${offerDraftKey}/counter`,
  },
  tradeView: (tradeOfferId: Number | '[tradeOfferId]') => `/trade/view/${tradeOfferId}`,
  tradeTypes: (
    tradeType: EnumParam<TradeTypesEnum, ':tradeType'>,
    status: EnumParam<StatusEnum, ':status', '', '?'> = 'all',
  ) => `/trade/${tradeType}/${status || ''}`,
  tradeOffers: (status: EnumParam<StatusEnum, ':status', '', '?'> = 'all') =>
    `/trade/offers/${status ?? ''}`,
  payForTrade: (tradeId: NumberParam<':tradeId'>) => `/trade/${tradeId}/pay`,
  showcaseItem: ({ userId, id }: { userId: NumberParam<':userId'>; id: NumberParam<':id'> }) =>
    `/user/${userId}/showcase/item/${id}`,
  showcaseItemDirect: ({ id }: { id: number }) => `/showcase-item/${id}`,
  showcaseItemTab: ({
    userId,
    id,
    tabKey,
  }: {
    userId: NumberParam<':userId'>;
    id: NumberParam<':id'>;
    tabKey: StringParam<':tabKey'>;
  }) => `/user/${userId}/showcase/item/${id}/${tabKey}`,
  sport: (sportSlug: StringParam<':sportSlug'>) => `/categories/${sportSlug}`,
  sportItemType: (
    sportSlug: StringParam<':sportSlug'>,
    itemTypeSlug: StringParam<':itemTypeSlug'>,
  ) => `/categories/${sportSlug}/${itemTypeSlug}`,
  sportItemTypeAttribute: (
    sportSlug: StringParam<':sportSlug'>,
    itemTypeSlug: StringParam<':itemTypeSlug'>,
    itemTypePropertySlug: StringParam<':propertySlug'>,
  ) => `/categories/${sportSlug}/${itemTypeSlug}/${itemTypePropertySlug}`,
  search: (
    params?:
      | (ShowcaseItemsAPIParams & {
          itemNotFound?: boolean;
        })
      | UsersListParams,
  ) => makeUrl('/search', params),
  auctions: {
    list: '/auctions',
    charity: {
      schedule: '/auctions/charity/schedule',
      list: '/auctions/charity/list',
      get: (auctionCharityId: NumberParam<':auctionCharityId'>) =>
        `/auctions/charity/${auctionCharityId}`,
      edit: (auctionCharityId: NumberParam<':auctionCharityId'>) =>
        `/auctions/charity/edit/${auctionCharityId}`,
    },
  },
  membersSearch: (params?: ShowcaseItemsAPIParams) => makeUrl('/search/members', params),
  saveItems: (params?: ShowcaseItemsAPIParams) => makeUrl('/saveItems', params),
  verifyEmail: (otp: NumberParam<':otp'>) => `/verify-email/${otp}`,
  verifyPhone: (otp: NumberParam<':otp'>) => `/verify-phone/${otp}`,
  watchlist: (tab: EnumParam<WatchlistPageTabs, ':tab'> = 'items') => `/user/watchlist/${tab}`,

  admin: {
    root: '/admin',
    login: '/admin/login',
    discounts: '/admin/discounts',
    coupons: '/admin/coupons',
    properties: '/admin/properties',
    charityApplicationsPendingApproval: '/admin/charity-applications/pending-approval', // TODO: implement
    users: (params?: AdminUsersQueryParams) => makeUrl('/admin/users', params),
    rsa: '/admin/rsa',
  },

  // todo: not implemented yet routes
  faq: '/faq',
  support: '/support',
  contactUs: '/contactUs',
  privacy: '/privacy',
  cookies: '/cookies',
  facebook: 'https://www.facebook.com/securetradeclub',
  twitter: 'https://twitter.com/securetradeclub',
  instagram: 'https://www.instagram.com/securetradeclub/',
  notifications: (params?: NotificationsAPIParams) => makeUrl('/notifications', params),
  coupons: '/coupons',
  googleConnectCallback: '/google/connect/callback',

  // Deprecated routes: we should take care of redirecting, users can save and share links, old links should be working properly
  deprecatedMyShowcase: '/user/showcase',
  deprecatedMyShowcaseItem: (id: NumberParam<':id'>) => `/user/showcase/item/${id}`,
  deprecatedMyShowcaseItemTab: ({
    id,
    tabKey,
  }: {
    id: NumberParam<':id'>;
    tabKey: StringParam<':tabKey'>;
  }) => `/user/showcase/item/${id}/${tabKey}`,
  blog: (params?: { offset: number }) => makeUrl('/blog', params),
  blogPost: ({ title, id }: { title?: string | null; id: string }) =>
    `/blog/post/${id}/${title?.replaceAll(' ', '-').toLocaleLowerCase()}`,
  blogCategory: ({ name, id, offset }: { name?: string | null; id: string; offset?: number }) =>
    makeUrl(`/blog/category/${id}/${name?.replaceAll(' ', '-').toLocaleLowerCase()}`, { offset }),
} as const;

export function toFullUrl(route: string) {
  return `${window.location.origin}${route}`;
}

// ### UTILITY TYPES ###
type OptionalParam = '' | '?';
type RouteParam<
  T, // the route param type string , number, etc
  ParamName extends string, // the param name ':id' , ':userId', etc
  Validation extends string, // the validation like '(\\d+)'
  Optional extends OptionalParam = '', // is this param an optional param = '' or '?'
> = Optional extends '?'
  ? // if optional param should have ? at the end, and can allow empty string as value
    T | undefined | '' | `${ParamName}${Validation}?`
  : // not optional param, should accept the param type only
    T | `${ParamName}${Validation}`;

type NumberParam<ParamName extends string, Optional extends OptionalParam = ''> = RouteParam<
  number,
  ParamName,
  // number validation util
  '(\\d+)',
  Optional
>;

type StringParam<
  ParamName extends string,
  Validation extends string = '',
  Optional extends OptionalParam = '',
> = RouteParam<string, ParamName, Validation, Optional>;

type EnumParam<
  Enum extends string,
  ParamName extends string,
  Validation extends string = '',
  Optional extends OptionalParam = '',
> = RouteParam<Enum, ParamName, Validation, Optional>;
