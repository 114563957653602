import {
  ProfilePhotoMetadata,
  ProfilePhotoVersion,
  UserProfilePhotoFields,
} from '../api/user/user.dto';

export const defaultProfilePhoto = '/images/defaultProfileImage.jpg';

const fields: Record<ProfilePhotoVersion, keyof UserProfilePhotoFields> = {
  small: 'profilePhotoSmall',
  medium: 'profilePhotoMedium',
  large: 'profilePhotoLarge',
  original: 'profilePhoto',
};

export const getProfilePhoto = (
  data: Partial<UserProfilePhotoFields> | { profilePhotoMetadata: ProfilePhotoMetadata | null },
  size: ProfilePhotoVersion,
): string => {
  const start = ProfilePhotoVersion.options.indexOf(size);
  const max = ProfilePhotoVersion.options.length;

  if ('profilePhotoMetadata' in data) {
    if (data.profilePhotoMetadata?.versions) {
      const { versions } = data.profilePhotoMetadata;
      for (let i = start; i <= max; i++) {
        const key = ProfilePhotoVersion.options[i];
        if (versions[key]) {
          return versions[key].url;
        }
      }
    }
  } else {
    for (let i = start; i <= max; i++) {
      const key = fields[ProfilePhotoVersion.options[i]];
      if (data[key]) {
        return data[key] as string;
      }
    }
  }

  return defaultProfilePhoto;
};
