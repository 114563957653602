import { useArchiveMessageMutation } from '@/api/chat/chat.api';
import { ConversationDTO } from '@/api/chat/chat.dto';
import { useModalState } from '@/hooks/useModalState';
import { Badge, Tooltip } from 'antd';
import cn from 'classnames';
import { FaChevronRight } from 'react-icons/fa';
import { MdDelete, MdRestoreFromTrash } from 'react-icons/md';
import ArchiveConversationModal from './ArchiveConversationModal';
const ConversationsWithUserItem = ({
  conversationItem,
  onClick,
  archived,
}: {
  conversationItem: ConversationDTO;
  onClick: () => void;
  archived?: boolean;
}) => {
  const modalState = useModalState();
  const archiveConversation = useArchiveMessageMutation();
  const archive = () => {
    archiveConversation.mutate({
      conversationId: conversationItem.id,
      archive: !archived,
    });

    modalState.close();
  };
  return (
    <div
      className={cn(
        'rounded-md border border-gray-f3 hover:bg-gray-f3 bg-gray-f9 px-1.5 py-2 cursor-pointer border-b  shadow flex flex-row items-center',
        archived ? 'border-b-gray-500 text-gray-500' : 'border-b-primary',
      )}
    >
      <div className="w-full flex flex-col" onClick={onClick}>
        <div className="text-sm">{conversationItem.subject}</div>
        <div className="text-xs">{conversationItem.messagesCount} Messages</div>
      </div>
      <Badge showZero={false} offset={[-20, 0]} count={conversationItem.unreadCount ?? 0} />

      <FaChevronRight size={25} className={archived ? 'text-gray-500' : 'text-primary'} />
      <ArchiveConversationModal
        showModal={modalState.showModal}
        onClose={modalState.close}
        onArchive={archive}
        archived={archived ?? false}
      />
      <Tooltip
        placement="top"
        title={archived ? 'Un-Archive' : 'Archive'}
        overlayInnerStyle={{
          padding: '3px 4px',
          fontSize: '10px',
          minHeight: '15px',
        }}
      >
        <button className="ml-2" onClick={modalState.open}>
          {archived ? (
            <MdRestoreFromTrash size={20} className="text-gray-400 hover:text-secondary-light" />
          ) : (
            <MdDelete size={20} className="text-gray-400 hover:text-danger-light" />
          )}
        </button>
      </Tooltip>
    </div>
  );
};

export default ConversationsWithUserItem;
