import { buildApiMutationHook } from '../../utils/api';
import axiosI from '../base.api';

export type CreateStripeAccountLinkParams = {
  returnUrl: string;
  refreshUrl: string;
};

export type CreateStripeAccountLinkResult = {
  url: string;
};

export const useCreateStripeAccountLink = buildApiMutationHook<
  CreateStripeAccountLinkParams,
  CreateStripeAccountLinkResult
>((data) => axiosI.post('/api/marketPlace/create-or-refresh-stripe-account', data));
