import { useCurrentUserId } from '@/features/user/currentUser.service';
import Link from 'next/link';
import { BsInstagram } from 'react-icons/bs';
import { FaFacebookF, FaTwitter } from 'react-icons/fa';
import routes from '../../../../routes/routes';
const socialMediaIconClasses = 'text-primary hover:text-primary-darker cursor-pointer';
const bottomLinksClasses = 'text-gray-8a font-bold text-[13px]';
const titleCategoriesClasses = 'text-white text-lg lg:text-xl font-bold';
const siteLinksClasses =
  'text-white text-left whitespace-nowrap hover:underline hover:text-white cursor-pointer';

const NewFooter = () => {
  const userId = useCurrentUserId();
  return (
    <footer className="bg-secondary-light flex flex-col">
      <div className="px-4 py-10 lg:px-12 lg:py-16 xl:pt-28 xl:pr-32 xl:pl-20">
        <div className="flex flex-col-reverse md:flex-row items-center justify-start md:justify-between gap-y-6 md:gap-x-6 lg:gap-x-10 xl:gap-x-20">
          <div className="flex items-center gap-x-4 lg:gap-x-14">
            <Link href={routes.home}>
              <a className="w-[86px] h-[86px]">
                <img className="w-full h-full" src="/images/header-logo.svg" alt="logo" />
              </a>
            </Link>

            <div className="flex flex-col items-start">
              <div className="text-2xl lg:text-[32px] text-gray-d9">Secure Trade Club</div>
              <hr className="border-gray-66 mt-5 mb-6 self-stretch" />
              <div className="text-xl font-bold text-white">Secure - Trade - Sell</div>
              <div className="text-white text-left mt-3.5">
                The industry’s first true platform designed
                <br /> by Collectors for Collectors, offering you the
                <br /> opportunity to Trade, Buy and Sell like never
                <br /> before!
              </div>
            </div>
          </div>

          <hr className="md:hidden self-stretch border-gray-66" />

          <div className="self-stretch xs:self-auto grid grid-cols-2 xs:grid-cols-4 justify-items-center gap-y-6 xs:gap-x-6 lg:gap-x-10 xl:gap-x-20">
            <div className="flex flex-col items-start gap-y-7">
              <div className={titleCategoriesClasses}>Buy</div>

              <div className="flex flex-col items-start gap-y-4">
                <Link href={routes.search()}>
                  <a className={siteLinksClasses}>Shop</a>
                </Link>
                <Link href={routes.sport('all')}>
                  <a className={siteLinksClasses}>Categories</a>
                </Link>
                <Link href={routes.offers.list}>
                  <a className={siteLinksClasses}>Buy Offers</a>
                </Link>
              </div>
            </div>

            <div className="flex flex-col items-start gap-y-7">
              <div className={titleCategoriesClasses}>Sell</div>

              <div className="flex flex-col items-start gap-y-4">
                {userId ? (
                  <Link href={routes.showcase({ userId })}>
                    <a className={siteLinksClasses}>Showcase</a>
                  </Link>
                ) : (
                  <Link href={routes.signUp()}>
                    <a className={siteLinksClasses}>Showcase</a>
                  </Link>
                )}
                <Link href={routes.addItem}>
                  <a className={siteLinksClasses}>Start Selling</a>
                </Link>
              </div>
            </div>

            <div className="flex flex-col items-start gap-y-7">
              <div className={titleCategoriesClasses}>Company</div>

              <div className="flex flex-col items-start gap-y-4">
                <Link href={routes.about}>
                  <a className={siteLinksClasses}>About</a>
                </Link>
                <Link href={routes.contactUs}>
                  <a className={siteLinksClasses}>Contact</a>
                </Link>
                <Link href={routes.blog()}>
                  <a className={siteLinksClasses}>Blog</a>
                </Link>
              </div>
            </div>

            <div className="flex flex-col items-start gap-y-7">
              <div className={titleCategoriesClasses}>Resources</div>

              <div className="flex flex-col items-start gap-y-4">
                <Link href={routes.contactUs}>
                  <a className={siteLinksClasses}>FAQ</a>
                </Link>
                <Link href={routes.contactUs}>
                  <a className={siteLinksClasses}>Support</a>
                </Link>
                <Link href={'#'}>
                  <a className={siteLinksClasses}>Shipping</a>
                </Link>
                <Link href={'#'}>
                  <a className={siteLinksClasses}>Security</a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr className="mx-4 md:mx-6 lg:mx-8 xl:mx-[70px] border-gray-66" />

      <div className="px-4 lg:px-12 pt-8 lg:pt-20 xl:px-20 pb-14 xs:pb-6 md:pb-14">
        <div className="flex flex-col-reverse md:flex-row items-center justify-start gap-y-6 lg:justify-center relative">
          <div
            className={`${bottomLinksClasses} self-start sm:self-center md:self-auto md:absolute left-0 -bottom-9 lg:bottom-auto`}
          >
            © 2022 Secure Trade Club, Inc. All right reserved.
          </div>

          <div
            className={
              'self-start sm:self-center md:self-auto flex items-center justify-center gap-x-4 xs:gap-x-8'
            }
          >
            <Link href={routes.terms}>
              <a className={bottomLinksClasses}>Terms & Conditions</a>
            </Link>
            <Link href={routes.privacy}>
              <a className={bottomLinksClasses}>Privacy Policy</a>
            </Link>
            <Link href={routes.cookies}>
              <a className={bottomLinksClasses}>Cookies</a>
            </Link>
          </div>

          <div className="self-start sm:self-center md:self-auto flex items-center justify-center gap-x-6 md:absolute right-24 lg:right-14">
            <a href={routes.facebook}>
              <FaFacebookF size={21} className={socialMediaIconClasses} />
            </a>
            <a href={routes.instagram}>
              <BsInstagram size={21} className={socialMediaIconClasses} />
            </a>
            <a href={routes.twitter}>
              <FaTwitter size={21} className={socialMediaIconClasses} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default NewFooter;
