import { useCurrentUserId } from '@/features/user/currentUser.service';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { AiOutlineSend } from 'react-icons/ai';
import { MdOutlinePlayDisabled } from 'react-icons/md';
import {
  useChatMessagesQuery,
  useMarkMessagesAsReadMutation,
  useSendMessageMutation,
} from '../../../../../api/chat/chat.api';
import { ChatMessageDTO } from '../../../../../api/chat/chat.dto';
import { useApiWebSocket } from '../../../../../hooks/useApiWebSocket';
import { ConversationWindowParams } from '../../../../../reducers/chatReducer';
import routes from '../../../../../routes/routes';
import ChatLayout from '../../ChatLayout';
import { useConversationState } from './Conversation.state';
import { MessageItem } from './MessageItem';

const Conversation = ({ windowParams }: { windowParams: ConversationWindowParams }) => {
  const { data: initialMessages, isLoading } = useChatMessagesQuery({
    conversationId: windowParams.conversation.id,
  });
  const [chatInput, setChatInput] = useState<string>('');
  const messagesListRef = useRef<HTMLDivElement>(null);
  const markMessagesAsReadMutation = useMarkMessagesAsReadMutation();
  const { messages, appendMessage } = useConversationState({
    initialMessages: initialMessages ?? [],
  });

  useEffect(() => {
    if (messages.length) {
      markMessagesAsRead(messages);
      scrollToBottom();
    }
  }, [messages]);

  const scrollToBottom = () => {
    if (messagesListRef.current) {
      const scrollHeight = messagesListRef.current.scrollHeight;
      const height = messagesListRef.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      messagesListRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };
  const markMessagesAsRead = (messages: ChatMessageDTO[]) => {
    const unreadIds = messages
      .filter((message) => message.readAt === null && message.sender.id !== currentUserId)
      .map((item) => item.id);
    if (unreadIds && unreadIds.length > 0) {
      markMessagesAsReadMutation.mutate({
        conversationId: windowParams.conversation.id,
        ids: unreadIds,
      });
    }
  };
  const sendNewMessage = useSendMessageMutation({
    onSuccess(data) {
      appendMessage(data);
      setChatInput('');
    },
  });

  function submitNewMessage(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (chatInput.length === 0) return;
    if (sendNewMessage.isLoading) return;
    sendNewMessage.mutate({ message: chatInput, conversationId: windowParams.conversation.id });
  }
  useApiWebSocket({
    onMessage(msg) {
      if (msg.type !== 'chatMessage') return;
      if (msg.message.conversationId !== windowParams.conversation.id) return;
      appendMessage(msg.message);
    },
  });
  const currentUserId = useCurrentUserId();

  const itemLink = windowParams.conversation.showcaseItemId
    ? routes.showcaseItem({
        userId: windowParams.otherUser.userId,
        id: windowParams.conversation.showcaseItemId,
      })
    : routes.tradeView(windowParams.conversation.tradeOfferId!);

  return (
    <ChatLayout
      windowTitle={`Chat with ${windowParams.otherUser.userName} about:`}
      subTitle={windowParams.conversation.subject}
      title="Messages:"
      loading={isLoading}
      linkTo={itemLink}
      archived={windowParams.archived}
    >
      <div className="h-full flex flex-col justify-between gap-y-2">
        <div ref={messagesListRef} className="flex flex-1 flex-col gap-y-2 overflow-auto h-full">
          {messages.length ? (
            !!currentUserId &&
            messages.map((item, index) => (
              <MessageItem key={index} message={item} currentUserId={currentUserId} />
            ))
          ) : (
            <p className="flex h-full justify-center items-center font-bold text-xl">
              Start Messaging
            </p>
          )}
        </div>

        {!windowParams.archived && (
          <form className="w-full border-t border-gray-cb pb-5 md:pb-0" onSubmit={submitNewMessage}>
            <div className="w-full p-2 flex flex-row items-center">
              <input
                value={chatInput}
                onChange={(value) => setChatInput(value.target.value)}
                className="w-full p-2 rounded-full border border-gray-cb"
                placeholder={`Message in ${windowParams.conversation.subject}`}
              />
              <button
                disabled={sendNewMessage.isLoading}
                type="submit"
                className="rounded-full mx-2 bg-primary p-3 text-white"
              >
                {sendNewMessage.isLoading ? (
                  <MdOutlinePlayDisabled size={30} />
                ) : (
                  <AiOutlineSend size={30} />
                )}
              </button>
            </div>
          </form>
        )}
      </div>
    </ChatLayout>
  );
};

export default Conversation;
