import cn from 'classnames';
import { useEffect, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BsArrowLeftCircle } from 'react-icons/bs';
import { useChatBoxState } from '../../../../hooks/useChatBoxState';
import AllConversations from './AllConversations/AllConversations';
import Conversation from './Conversation/Conversation';
import StartConversation from './Conversation/StartConversation';
import ConversationsWithUser from './ConversationsWithUser/ConversationsWithUser';
const ChatConversations = () => {
  const { windowParams, setConversationsWithUser, setAllConversations, closeChatBox } =
    useChatBoxState();

  const [archived, setArchived] = useState<boolean>(false);

  const backHandler = () => {
    switch (windowParams.index) {
      case 2:
        setConversationsWithUser({ otherUser: windowParams.otherUser, archived });
        break;
      case 1:
        setAllConversations(archived);
        break;
      case 0:
        setAllConversations(false);
        setArchived(false);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if ('archived' in windowParams && windowParams.archived) setArchived(true);
  }, [windowParams]);
  return (
    <div
      className={cn(
        'bg-white w-full h-full border-2 rounded-lg shadow-xl relative',
        archived ? 'border-gray-500' : 'border-primary',
      )}
    >
      {(windowParams.index > 0 || (windowParams.index === 0 && archived)) && (
        <BsArrowLeftCircle
          className="absolute left-1 top-2 text-white transition"
          size={23}
          cursor="pointer"
          onClick={backHandler}
        />
      )}
      <AiOutlineCloseCircle
        className="absolute right-1 top-2 text-white hover:text-danger transition"
        size={25}
        cursor="pointer"
        onClick={closeChatBox}
      />
      {windowParams.index === 0 ? (
        <AllConversations windowParams={windowParams} setArchived={setArchived} />
      ) : windowParams.index === 1 ? (
        windowParams.otherUser && <ConversationsWithUser windowParams={windowParams} />
      ) : (
        windowParams.index === 2 &&
        ('conversation' in windowParams && windowParams.conversation ? (
          <Conversation windowParams={windowParams} />
        ) : (
          'tempConversation' in windowParams &&
          windowParams.tempConversation && <StartConversation windowParams={windowParams} />
        ))
      )}
    </div>
  );
};

export default ChatConversations;
