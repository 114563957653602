import { Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CLOSE_CHAT_BOX,
  FINISH_TRANSFORMING,
  OPEN_CHAT_BOX,
  SET_ALL_CONVERSATIONS,
  SET_CONVERSATION,
  SET_CONVERSATIONS_WITH_USER,
  SET_START_CONVERSATION,
  SET_WINDOW_PARAMS,
} from '../actions/actionTypes';
import {
  ChatWindowParams,
  ConversationsWithUserWindowParams,
  ConversationWindowParams,
  StartConversationWindowParams,
} from '../reducers/chatReducer';
import { RootState } from '../utils';

type Action = {
  type: string;
  payload?: ChatWindowParams;
};

export const useChatBoxState = () => {
  const dispatch = useDispatch<Dispatch<Action>>();
  //opening and closing chat box actions
  const isChatBoxOpen = useSelector((state: RootState) => state.chatReducer.isChatBoxOpen);
  const openChatBox = () => {
    dispatch({ type: OPEN_CHAT_BOX });
  };
  const closeChatBox = () => {
    dispatch({ type: CLOSE_CHAT_BOX });
  };

  const isTransforming = useSelector((state: RootState) => state.chatReducer.isTransforming);
  const finishTransforming = () => {
    dispatch({ type: FINISH_TRANSFORMING });
  };

  //chat box window params actions
  const windowParams = useSelector((state: RootState) => state.chatReducer.windowParams);

  const setAllConversations = (archived?: boolean) => {
    dispatch({ type: SET_ALL_CONVERSATIONS, payload: { index: 0, archived } });
  };

  const setConversationsWithUser = ({
    otherUser,
    archived,
  }: Pick<ConversationsWithUserWindowParams, 'otherUser' | 'archived'>) => {
    dispatch({ type: SET_CONVERSATIONS_WITH_USER, payload: { index: 1, otherUser, archived } });
  };

  const setConversation = ({
    otherUser,
    conversation,
    archived,
  }: Omit<ConversationWindowParams, 'index'>) => {
    dispatch({ type: SET_CONVERSATION, payload: { index: 2, otherUser, conversation, archived } });
  };

  const setStartConversation = ({
    otherUser,
    tempConversation,
  }: Omit<StartConversationWindowParams, 'index'>) => {
    dispatch({ type: SET_START_CONVERSATION, payload: { index: 2, otherUser, tempConversation } });
  };

  const setWindowParams = (newWindowParams: ChatWindowParams) => {
    dispatch({ type: SET_WINDOW_PARAMS, payload: newWindowParams });
  };

  return {
    isChatBoxOpen,
    openChatBox,
    closeChatBox,
    isTransforming,
    finishTransforming,
    windowParams,
    setAllConversations,
    setConversationsWithUser,
    setConversation,
    setStartConversation,
    setWindowParams,
  };
};
