import { useCreateStripeAccountLink } from '@/api/user/stripeAccount.api';
import { UserFullDto } from '@/types';
import { ApiError, handleApiError } from '@/utils/api';
import { deleteFromStorage, useReadLocalStorage, writeStorage } from '@/utils/localStorage';
import { MB } from '@/utils/size';
import dayjs from 'dayjs';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { useState } from 'react';

/**
 * JSON.stringify writes dates as string and they are parsed as string by JSON.parse.
 * Therefore need additional data-type for raw values stored in the local storage.
 */
type UserPersistentDataRaw = {
  id: number;
  accessToken: string;
  verificationModalShownAt: null | string;
};

export type UserPersistentData = {
  id: number;
  accessToken: string;
  verificationModalShownAt: null | Date;
};

export const userProfilePhotoMaxSize = 10 * MB;

const storageKey = 'userData';

const deleteCurrentUserPersistentDataIfInvalid = getCurrentUserPersistentData;

export function getCurrentUserPersistentData(): UserPersistentData | undefined {
  const storedJSON = localStorage.getItem(storageKey);
  if (storedJSON === null) return;

  try {
    const raw: UserPersistentDataRaw = JSON.parse(storedJSON);
    const verificationModalShownAt = unserializeDate(raw.verificationModalShownAt);

    const decoded: JwtPayload & { id?: number } = jwtDecode(raw.accessToken);

    const isTokenValid = (decoded.exp && decoded.exp * 1000 > new Date().getTime()) || false;
    if (isTokenValid && typeof decoded.id === 'number') {
      return {
        accessToken: raw.accessToken,
        id: decoded.id,
        verificationModalShownAt,
      };
    }
  } catch (_) {}

  deleteFromStorage(storageKey);
  return;
}

export const setCurrentUserPersistentData = (data: UserPersistentData | null) =>
  data ? writeStorage(storageKey, data) : deleteFromStorage(storageKey);

export const useCurrentUserPersistentData = () => {
  return useReadLocalStorage<UserPersistentData>(storageKey);
};

export const useIsAuthenticated = () => {
  return Boolean(useCurrentUserPersistentData());
};

export const useCurrentUserToken = () => {
  const data = useCurrentUserPersistentData();
  return data?.accessToken;
};

export const useCurrentUserId = () => {
  const data = useCurrentUserPersistentData();
  return data?.id;
};

export const getCanReceivePayment = (user: UserFullDto) => {
  return user.canReceivePayment;
};

export const getIsMediator = (user: UserFullDto) => {
  return Boolean(
    (user.membershipLevel === 'VIP' || user.membershipLevel === 'Charity') && user.isMediator,
  );
};

export const useCreateStripeAccount = ({
  returnRoute,
  refreshRoute,
  onError,
}: {
  returnRoute: string;
  refreshRoute: string;
  onError(error: ApiError): void;
}) => {
  const { mutateAsync: createAccountLink, isLoading, error } = useCreateStripeAccountLink();
  const [isRedirecting, setIsRedirecting] = useState(false);

  const createStripeAccount = async () => {
    try {
      const origin = window.location.origin;
      const { url } = await createAccountLink({
        returnUrl: `${origin}${returnRoute}`,
        refreshUrl: `${origin}${refreshRoute}`,
      });
      setIsRedirecting(true);
      window.location.href = url;
    } catch (error: unknown) {
      onError(handleApiError(error));
    }
  };

  return { createStripeAccount, isLoading: isLoading || isRedirecting, error };
};

if (typeof window !== 'undefined') {
  deleteCurrentUserPersistentDataIfInvalid();
}

export function unserializeDate(raw: null | string): null | Date {
  if (raw == null) return raw;
  const v = new Date(raw);
  if (!dayjs(v).isValid()) return null;
  return v;
}
