import {
  buildApiMutationHook,
  buildApiQueryHook,
  buildApiQueryNoParamsHook,
} from '../../utils/api';
import { queryClient } from '../../utils/queryClient';
import axiosI from '../base.api';
import {
  ArchiveConversationParams,
  ChatMessageDTO,
  ChatMessagesAPIParams,
  ConversationDTO,
  ConversationWithUserAPIParams,
  findConversationDTO,
  SendMessageParams,
  SendUnthreadedMessageParams,
  StartNewConversationParams,
  UsersConversationDTO,
} from './chat.dto';

type conversationsQueryParams = {
  archived?: boolean;
};
export const useAllConversationsQuery = buildApiQueryHook<
  conversationsQueryParams,
  UsersConversationDTO[]
>('allConversations', (params) => axiosI.get('/chat/conversation/all', { params }));

export const useAllUnreadMessagesQuery = buildApiQueryNoParamsHook<number>(
  'allUnreadMessages',
  () => axiosI.get('/chat/conversation/all/unread-count'),
);

export const useFindConversationsQuery = buildApiQueryHook<
  StartNewConversationParams,
  findConversationDTO
>('conversation', (params) => axiosI.get('/chat/conversation/find', { params }));

export const useAllConversationsWithUserQuery = buildApiQueryHook<
  ConversationWithUserAPIParams,
  ConversationDTO[]
>('conversationsWithUser', ({ otherUserId, archived }) =>
  axiosI.get(`/chat/conversation/user/${otherUserId}`, { params: { archived } }),
);

export const useChatMessagesQuery = buildApiQueryHook<ChatMessagesAPIParams, ChatMessageDTO[]>(
  'chatMessages',
  ({ conversationId }) => axiosI.get(`/chat/conversation/${conversationId}/messages`),
);

export const useUnthreadedChatMessagesQuery = buildApiQueryHook<number, ChatMessageDTO[]>(
  `unthreadedChatMessages`,
  (param) => axiosI.get(`/chat/user/${param}/messages`),
);

export const useStartNewConversationMutation = buildApiMutationHook<
  StartNewConversationParams,
  ConversationDTO
>(
  (params) => axiosI.post('/chat/conversation/new', params),
  (options) => ({
    ...options,
    onSuccess(...args) {
      args[1].showcaseItemId ? delete args[1].tradeOfferId : delete args[1].showcaseItemId;
      queryClient.invalidateQueries(['conversation', args[1]]);
      options?.onSuccess?.(...args);
    },
  }),
);

export const useSendMessageMutation = buildApiMutationHook<SendMessageParams, ChatMessageDTO>(
  (params) =>
    axiosI.post(`/api/chat/conversation/${params.conversationId}/messages/new`, {
      message: params.message,
    }),
  (options) => ({
    ...options,
    onSuccess(...args) {
      queryClient.invalidateQueries(['chatMessages', args[1].conversationId]);
      options?.onSuccess?.(...args);
    },
  }),
);

export const useSendUnthreadedMessageMutation = buildApiMutationHook<
  SendUnthreadedMessageParams,
  ChatMessageDTO
>((params) =>
  axiosI.post(`/api/chat/user/${params.otherUserId}/messages/new`, { message: params.message }),
);

export const useMarkMessagesAsReadMutation = buildApiMutationHook<{
  conversationId?: number;
  ids: number[];
}>(
  (params) =>
    axiosI.post(`/chat/conversation/${params.conversationId}/messages/mark-as-read`, params),
  (options) => ({
    ...options,
    onSuccess(...args) {
      queryClient.invalidateQueries('chatMessages');
      queryClient.invalidateQueries('allUnreadMessages');
      options?.onSuccess?.(...args);
    },
  }),
);

export const useArchiveMessageMutation = buildApiMutationHook<ArchiveConversationParams, void>(
  (params) =>
    axiosI.post(`/api/chat/conversation/${params.conversationId}/archive`, {
      archive: params.archive,
    }),
  (options) => ({
    ...options,
    onSuccess(...args) {
      queryClient.invalidateQueries(['conversationsWithUser']);
      options?.onSuccess?.(...args);
    },
  }),
);

export const useCountArchivedConversationsQuery = buildApiQueryNoParamsHook<number>(
  'archivedConversationsCount',
  () => axiosI.get('/chat/conversation/archived/count'),
);
