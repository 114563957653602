import Router from 'next/router';
import { dropKeysWithUndefinedValues } from './dropKeysWithUndefinedValues';

/**
 * '1'       => '1'
 * 1         => '1'
 * [1,2,3]   => '1,2,3'
 * true      => 'true'
 * undefined => // completely omitted
 */
export const makeUrl = <T extends Record<string, undefined | string | number | number[] | boolean>>(
  path: string,
  params?: T,
) => {
  let url = path;
  if (params) {
    // we don't want our urls to have `?someKey=undefined` pieces, so we strip out all keys with undefined values.
    const filteredParams = dropKeysWithUndefinedValues(params);
    // all of those types (string | number | number[] | boolean) are automatically converted to string so we can convert the type here
    const search = new URLSearchParams(filteredParams as Record<string, string>).toString();
    if (search) {
      url += `?${search}`;
    }
  }
  return url;
};

export const getCurrentPath = () => {
  return Router.asPath.split('?')[0];
};
export const getCurrentQueryParams = () => {
  return Router.asPath.split('?')[1] ?? null;
};
