// COMMON
export const ERROR = 'ERROR';

// LOADER
export const ENABLE_LOADER = 'ENABLE_LOADER';
export const DISABLE_LOADER = 'DISABLE_LOADER';

// SHIPPING
export const LOAD_SHIPPING_DETAILS = 'LOAD_SHIPPING_DETAILS';

// UI State
export const TOGGLE_SEARCH_FILTERS = 'TOGGLE_SEARCH_FILTERS';

// Chat
export const RESET_CHAT_STATE = 'RESET_CHAT_STATE';

export const OPEN_CHAT_BOX = 'OPEN_CHAT_BOX';

export const CLOSE_CHAT_BOX = 'CLOSE_CHAT_BOX';

export const SET_ALL_CONVERSATIONS = 'SET_ALL_CONVERSATIONS';

export const SET_CONVERSATIONS_WITH_USER = 'SET_CONVERSATIONS_WITH_USER';

export const SET_CONVERSATION = 'SET_CONVERSATION';

export const SET_START_CONVERSATION = 'SET_START_CONVERSATION';

export const SET_WINDOW_PARAMS = 'SET_WINDOW_PARAMS';

export const FINISH_TRANSFORMING = 'FINISH_TRANSFORMING';
