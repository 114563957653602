import CustomRedirect from '@/components/CustomRedirect';
import {
  useCurrentUserPersistentData,
  UserPersistentData,
} from '@/features/user/currentUser.service';
import { getCurrentPath } from '@/utils/url';
import routes from '../../../routes/routes';

export type UserDataGuardProps = {
  render(userData: UserPersistentData): JSX.Element | null;
  fallback?(): JSX.Element | null;
};

export const UserDataGuard = ({
  render,
  fallback = () => <CustomRedirect to={routes.login({ returnUrl: getCurrentPath() })} />,
}: UserDataGuardProps) => {
  const userData = useCurrentUserPersistentData();
  if (!userData) {
    return fallback();
  }

  return render(userData);
};
