import { useCurrentUserToken } from '@/features/user/currentUser.service';
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { WebSocketMessage } from '../types';
import { useTrackingRef } from './useTrackingRef';

const socketUrl = process.env.NEXT_PUBLIC_API_WS_URL;
if (!socketUrl) throw new Error(`NEXT_PUBLIC_API_WS_URL must be set`);

const ApiWebSocketContext = createContext<WebSocket | undefined>(undefined);

export function ApiWebSocketProvider({ children }: { children?: ReactNode }) {
  const accessToken = useCurrentUserToken();
  const [webSocket, setWebSocket] = useState<WebSocket | undefined>(undefined);

  useEffect(() => {
    if (!accessToken) {
      setWebSocket(undefined);
      return;
    }
    const newWebSocket = new WebSocket(socketUrl, ['accessToken', accessToken]);
    setWebSocket(newWebSocket);

    return () => {
      newWebSocket.close();
    };
  }, [accessToken]);

  return <ApiWebSocketContext.Provider value={webSocket}>{children}</ApiWebSocketContext.Provider>;
}

export const useApiWebSocket = ({
  onMessage,
}: {
  onMessage(message: WebSocketMessage): void | Promise<void>;
}) => {
  const webSocket = useContext(ApiWebSocketContext);
  const onMessageRef = useTrackingRef(onMessage); // can't use onMessage directly in event listener because it can change

  // be aware of what variables are wrapped in a closure here
  const onRawMessage = useCallback(
    (event: MessageEvent<unknown>) => {
      if (typeof event.data !== 'string') {
        console.error('Web socket message has non-string data, unexpected.', event);
        return;
      }
      let message: WebSocketMessage;
      try {
        message = JSON.parse(event.data);
      } catch (err) {
        console.error('Error parsing web socket response.', err);
        return;
      }

      onMessageRef.current(message);
    },
    [onMessageRef],
  );

  useEffect(() => {
    if (!webSocket) return;

    webSocket.addEventListener('message', onRawMessage);
    return () => {
      webSocket.removeEventListener('message', onRawMessage);
    };
  }, [webSocket, onRawMessage]);
};
