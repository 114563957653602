import React, { HtmlHTMLAttributes } from 'react';

interface LoaderProps extends HtmlHTMLAttributes<HTMLImageElement> {
  width?: number;
}

const StandaloneLoader: React.FC<LoaderProps> = ({ width = 72, ...props }) => {
  return <img width={width} src="/images/ring-spinner.gif" alt="loader" {...props} />;
};

export default StandaloneLoader;
