import { AnimatedModal } from '@/components/@next/AnimatedModal/AnimatedModal';

type Props = {
  showModal: boolean;
  onClose: () => void;
  onArchive: () => void;
  archived: boolean;
};

const ArchiveConversationModal = ({ showModal, onArchive, onClose, archived }: Props) => {
  return (
    <AnimatedModal showModal={showModal} onBackdropClick={onClose}>
      <div className="py-8 px-10 flex flex-col items-start text-left">
        <div className="font-bold text-[26px] text-secondary">
          {archived ? 'Un-Archive' : 'Archive'} Conversation
        </div>

        <div className="text-gray-8a mt-3.5">
          Are you sure you want to {archived ? 'un-archive' : 'archive'} this conversation?
        </div>
        <div className="mt-10 self-stretch flex justify-between items-center">
          <button onClick={onClose} className="btn-new btn-primary-outline">
            No
          </button>
          <button
            onClick={() => {
              onArchive();
            }}
            className="btn-new btn-primary"
          >
            Yes
          </button>
        </div>
      </div>
    </AnimatedModal>
  );
};

export default ArchiveConversationModal;
