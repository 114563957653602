import { message } from 'antd';
import axios, { AxiosResponse } from 'axios';
import * as R from 'ramda';
import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { DataUpdateFunction } from 'react-query/types/core/utils';
import { ErrorDetail } from './error';
import { queryClient } from './queryClient';
import { isDataUpdateFunction, useExtendMutation } from './reactQuery';

interface _ApiResponse {
  responseCode: boolean;
}

export type ApiResponse<T = Record<string, never>> = T & _ApiResponse;

export type ApiSuccessResponse<T = undefined> = ApiResponse<{
  responseCode: true;
  data: T;
}>;

export type ApiErrorResponse<T extends ErrorDetail = ErrorDetail> = ApiResponse<{
  responseCode: false;
  message: string;
  detail: T;
}>;

export class ApiError extends Error {
  constructor(message: string, public detail: ErrorDetail, public originalError?: unknown) {
    super(message);
  }
}

export function isApiError(err: unknown): err is ApiError {
  return err instanceof ApiError;
}

function convertApiError(err: unknown): ApiError {
  if (err instanceof ApiError) return err;
  if (axios.isAxiosError(err)) {
    return new ApiError(
      err.response == null
        ? 'Server unavailable.'
        : err.response?.data.message ?? 'Unknown api response.',
      err.response == null
        ? { errorCode: 'network-error' }
        : err.response?.data.detail ?? {
            errorCode: 'unknown-error',
          },
      err,
    );
  } else {
    return new ApiError(
      'Unknown api response.',
      {
        errorCode: 'unknown-error',
      },
      err,
    );
  }
}

/**
 * Convert unknown error to strongly typed error and
 * log bad errors (which are likely to be bugs) so they can
 * be picked up by sentry.io.
 */
export function handleApiError(err: unknown): ApiError {
  // no need to double logging, so if it already was converted, we just return it
  if (err instanceof ApiError) return err;

  const apiError = convertApiError(err);
  if (apiError.detail.errorCode === 'unknown-error') {
    console.error('Unknown api error:', apiError.message, apiError.detail, apiError.originalError);
  } else if (apiError.detail.errorCode === 'not-implemented-error') {
    console.error(
      'Not Implemented api error:',
      apiError.message,
      apiError.detail,
      apiError.originalError,
    );
  } else if (apiError.detail.errorCode === 'unreachable-error') {
    console.error(
      'Unreachable api error:',
      apiError.message,
      apiError.detail,
      apiError.originalError,
    );
  }
  return apiError;
}

/**
 * @throws {ApiError}
 */
export async function handleApiResult<T = unknown>(
  promise: Promise<AxiosResponse<{ data: T }>>,
): Promise<T> {
  try {
    const { data } = await promise;
    return (data as any).data;
  } catch (err: unknown) {
    const apiError = handleApiError(err);
    throw apiError;
  }
}

type SetDataFor<Params, Result> = (
  params: Params,
  updater: Result | DataUpdateFunction<Result | undefined, Result | typeof SkipUpdate>,
) => Result | undefined;

type SetDataForNoParams<Result> = (
  updater: Result | DataUpdateFunction<Result | undefined, Result | typeof SkipUpdate>,
) => Result | undefined;

export const SkipUpdate = Symbol('skip-update');
function mkSetDataFor<Params, Result>({
  queryKey,
}: {
  queryKey: (params: Params) => QueryKey;
}): SetDataFor<Params, Result> {
  return (params, updater) => {
    try {
      return queryClient.setQueryData<Result>(queryKey(params), (data) => {
        if (isDataUpdateFunction(updater)) {
          const res = updater(data);
          if (res === SkipUpdate) {
            throw SkipUpdate;
          }
          return res;
        } else {
          return updater;
        }
      });
    } catch (err) {
      if (err === SkipUpdate) {
        return undefined;
      }
      throw err;
    }
  };
}
function mkSetDataForNoParams<Result>({
  queryKey,
}: {
  queryKey: QueryKey;
}): SetDataForNoParams<Result> {
  return (updater) => {
    try {
      return queryClient.setQueryData<Result>(queryKey, (data) => {
        if (isDataUpdateFunction(updater)) {
          const res = updater(data);
          if (res === SkipUpdate) {
            throw SkipUpdate;
          }
          return res;
        } else {
          return updater;
        }
      });
    } catch (err) {
      if (err === SkipUpdate) {
        return undefined;
      }
      throw err;
    }
  };
}

export type ApiQueryOptions<Result, Select = Result> = Omit<
  UseQueryOptions<Result, ApiError, Select>,
  'queryFn' // queryFn is omitted because it is set at query hook creation
>;

type ApiQueryHook<Params, Result> = (
  params: Params,
  options?: ApiQueryOptions<Result>,
) => UseQueryResult<Result, ApiError>;

export const buildApiQueryHook = <Params, Result>(
  cacheKey: string | ((params: Params) => QueryKey),
  fetch: (params: Params) => Promise<AxiosResponse<{ data: Result }>>,
  partialOrMap:
    | Partial<ApiQueryOptions<Result>>
    | ((options?: ApiQueryOptions<Result>) => undefined | ApiQueryOptions<Result>) = (x) => x,
): ApiQueryHook<Params, Result> => {
  // cannot use ApiQueryOptions with Select, because then partial merge between default options and call-site options cannot be done
  // therefore there is buildApiQueryWithSelectHook when Select is needed.

  const useSpecificQuery = (params: Params, options?: ApiQueryOptions<Result>) => {
    options =
      typeof partialOrMap === 'function'
        ? partialOrMap(options)
        : options
        ? mergeOptions(partialOrMap, options)
        : partialOrMap;

    return useQuery<Result, ApiError>(
      options?.queryKey ?? (typeof cacheKey === 'string' ? [cacheKey, params] : cacheKey(params)),
      () => handleApiResult<Result>(fetch(params)),
      {
        ...options,
        onError(error: ApiError) {
          if (options?.onError) options.onError(error);
          else message.error(error.message);
        },
      },
    );
  };

  return useSpecificQuery;
};

export const buildApiQueryWithTransformHook = <Params, RawResult, Result = RawResult>(
  cacheKey: string | ((params: Params) => QueryKey),
  fetch: (params: Params) => Promise<AxiosResponse<{ data: RawResult }>>,
  transform: (raw: RawResult) => Result,
  partialOrMap:
    | Partial<ApiQueryOptions<Result>>
    | ((options?: ApiQueryOptions<Result>) => undefined | ApiQueryOptions<Result>) = (x) => x,
): ApiQueryHook<Params, Result> => {
  // cannot use ApiQueryOptions with Select, because then partial merge between default options and call-site options cannot be done
  // therefore there is buildApiQueryWithSelectHook when Select is needed.

  const useSpecificQuery = (params: Params, options?: ApiQueryOptions<Result>) => {
    options =
      typeof partialOrMap === 'function'
        ? partialOrMap(options)
        : options
        ? mergeOptions(partialOrMap, options)
        : partialOrMap;

    return useQuery<Result, ApiError>(
      options?.queryKey ?? (typeof cacheKey === 'string' ? [cacheKey, params] : cacheKey(params)),
      () => handleApiResult<RawResult>(fetch(params)).then(transform),
      {
        ...options,
        onError(error: ApiError) {
          if (options?.onError) options.onError(error);
          else message.error(error.message);
        },
      },
    );
  };

  return useSpecificQuery;
};

type ApiQueryWithSelectHook<Params, Result> = <Select = Result>(
  params: Params,
  options?: ApiQueryOptions<Result, Select>,
) => UseQueryResult<Select, ApiError>;

export const buildApiQueryWithSelectHook = <Params, Result>(
  cacheKey: string | ((params: Params) => QueryKey),
  fetch: (params: Params) => Promise<AxiosResponse<{ data: Result }>>,
): ApiQueryWithSelectHook<Params, Result> => {
  const useSpecificQuery = buildApiQueryHook(cacheKey, fetch);
  return useSpecificQuery as any;
};

type BuildApiQueryHookFullResultUnmapped<Params, Result> = {
  use: ApiQueryHook<Params, Result>;
  key: (params: Params) => QueryKey;
  setDataFor: SetDataFor<Params, Result>;
};

export type BuildApiQueryHookFullResult<Params, Result, Name extends string> = {
  [Property in keyof BuildApiQueryHookFullResultUnmapped<
    Params,
    Result
  > as `${Property}${Name}`]: BuildApiQueryHookFullResultUnmapped<Params, Result>[Property];
};

function makeBuildApiQueryHookFullResult<Params, Result, Name extends string>(
  name: Name,
  result: BuildApiQueryHookFullResultUnmapped<Params, Result>,
): BuildApiQueryHookFullResult<Params, Result, Name> {
  return {
    [`key${name}`]: result.key,
    [`use${name}`]: result.use,
    [`setDataFor${name}`]: result.setDataFor,
  } as BuildApiQueryHookFullResult<Params, Result, Name>;
}

export const buildApiQueryHookFull =
  <Name extends `${string}Query`>(name: Name) =>
  <Params = never, RawResult = unknown, Result = RawResult>(
    cacheKey: string | ((params: Params) => QueryKey),
    fetch: (params: Params) => Promise<AxiosResponse<{ data: RawResult }>>,
    transform: (raw: RawResult) => Result,
    partialOrMap:
      | Partial<ApiQueryOptions<Result>>
      | ((options?: ApiQueryOptions<Result>) => undefined | ApiQueryOptions<Result>) = (x) => x,
  ): BuildApiQueryHookFullResult<Params, Result, Name> => {
    const queryKey =
      typeof cacheKey === 'string'
        ? (params: Params) => [cacheKey, params]
        : (params: Params) => cacheKey(params);
    return makeBuildApiQueryHookFullResult(name, {
      key: queryKey,
      use: buildApiQueryWithTransformHook(queryKey, fetch, transform, partialOrMap),
      setDataFor: mkSetDataFor<Params, Result>({ queryKey }),
    });
  };

type ApiQueryNoParamsWithSelectHook<Result> = <Select = Result>(
  options?: ApiQueryOptions<Result, Select>,
) => UseQueryResult<Select, ApiError>;

export const buildApiQueryNoParamsWithSelectHook = <Result>(
  cacheKey: string | string[],
  fetch: () => Promise<AxiosResponse<{ data: Result }>>,
): ApiQueryNoParamsWithSelectHook<Result> => {
  const useSpecificQuery = buildApiQueryNoParamsHook<Result>(cacheKey, fetch);
  return useSpecificQuery as any;
};

export type ApiQueryNoParamsHook<Result> = (
  options?: ApiQueryOptions<Result>,
) => UseQueryResult<Result, ApiError>;

export const buildApiQueryNoParamsHook = <Result>(
  cacheKey: string | string[],
  fetch: () => Promise<AxiosResponse<{ data: Result }>>,
  partialOrMap:
    | Partial<ApiQueryOptions<Result>>
    | ((options?: ApiQueryOptions<Result>) => undefined | ApiQueryOptions<Result>) = (x) => x,
): ApiQueryNoParamsHook<Result> => {
  // cannot use ApiQueryOptions with Select, because then partial merge between default options and call-site options cannot be done
  // therefore there is buildApiQueryNoParamsWithSelectHook when Select is needed.
  const useSpecificQuery = buildApiQueryHook(() => cacheKey, fetch, partialOrMap);
  return (options) => useSpecificQuery(undefined, options);
};

export const buildApiQueryNoParamsWithTransformHook = <RawResult, Result = RawResult>(
  cacheKey: string | string[],
  fetch: () => Promise<AxiosResponse<{ data: RawResult }>>,
  transform: (raw: RawResult) => Result,
  partialOrMap:
    | Partial<ApiQueryOptions<Result>>
    | ((options?: ApiQueryOptions<Result>) => undefined | ApiQueryOptions<Result>) = (x) => x,
): ApiQueryNoParamsHook<Result> => {
  // cannot use ApiQueryOptions with Select, because then partial merge between default options and call-site options cannot be done
  // therefore there is buildApiQueryNoParamsWithSelectHook when Select is needed.
  const useSpecificQuery = buildApiQueryWithTransformHook(
    () => cacheKey,
    fetch,
    transform,
    partialOrMap,
  );
  return (options) => useSpecificQuery(undefined, options);
};

type BuildApiQueryNoParamsHookFullResultUnmapped<Result> = {
  use: ApiQueryNoParamsHook<Result>;
  key: QueryKey;
  setDataFor: SetDataForNoParams<Result>;
};

export type BuildApiQueryNoParamsHookFullResult<Result, Name extends string> = {
  [Property in keyof BuildApiQueryNoParamsHookFullResultUnmapped<Result> as `${Property}${Name}`]: BuildApiQueryNoParamsHookFullResultUnmapped<Result>[Property];
};

function makeBuildApiQueryNoParamsHookFullResult<Result, Name extends string>(
  name: Name,
  result: BuildApiQueryNoParamsHookFullResultUnmapped<Result>,
): BuildApiQueryNoParamsHookFullResult<Result, Name> {
  return {
    [`key${name}`]: result.key,
    [`use${name}`]: result.use,
    [`setDataFor${name}`]: result.setDataFor,
  } as BuildApiQueryNoParamsHookFullResult<Result, Name>;
}

export const buildApiQueryNoParamsHookFull =
  <Name extends `${string}Query`>(name: Name) =>
  <Result = unknown>(
    cacheKey: string | string[],
    fetch: () => Promise<AxiosResponse<{ data: Result }>>,
    partialOrMap:
      | Partial<ApiQueryOptions<Result>>
      | ((options?: ApiQueryOptions<Result>) => undefined | ApiQueryOptions<Result>) = (x) => x,
  ): BuildApiQueryNoParamsHookFullResult<Result, Name> => {
    return makeBuildApiQueryNoParamsHookFullResult(name, {
      key: cacheKey,
      use: buildApiQueryNoParamsHook(cacheKey, fetch, partialOrMap),
      setDataFor: mkSetDataForNoParams({ queryKey: cacheKey }),
    });
  };

export type ApiMutationOptions<Params, Result, TError = ApiError> = Omit<
  UseMutationOptions<Result, TError, Params>,
  'mutationFn'
>;

export type ApiMutationHook<Params, Result, TError = ApiError> = (
  options?: ApiMutationOptions<Params, Result, TError>,
) => UseMutationResult<Result, TError, Params>;

export const buildApiMutationHook = <Params = void, Result = void>(
  fetch: (params: Params) => Promise<AxiosResponse<{ data: Result }>>,
  partialOrMap:
    | Partial<ApiMutationOptions<Params, Result>>
    | ((
        options?: ApiMutationOptions<Params, Result>,
      ) => undefined | ApiMutationOptions<Params, Result>) = (x) => x,
): ApiMutationHook<Params, Result> => {
  const useSpecificMutation = (options?: ApiMutationOptions<Params, Result>) => {
    options =
      typeof partialOrMap === 'function'
        ? partialOrMap(options)
        : options
        ? mergeOptions(partialOrMap, options)
        : partialOrMap;

    return useMutation<Result, ApiError, Params>(
      (params: Params) => handleApiResult<Result>(fetch(params)),
      {
        ...options,
        onError(error, ...rest) {
          if (options?.onError) options.onError(error, ...rest);
          else message.error(error.message);
        },
      },
    );
  };

  return useSpecificMutation;
};

export const extendMutationHook =
  <Params, Result>(mutationHook: ApiMutationHook<Params, Result>) =>
  <ParamsOut = Params, ResultOut = Result>(maps: {
    mapResult?: (result: Result) => Promise<ResultOut>;
    mapVariables?: (vars: ParamsOut) => Params;
  }): ApiMutationHook<ParamsOut, ResultOut, unknown> =>
  (options) => {
    const mutation = mutationHook();
    const extended = useExtendMutation(mutation, {
      ...maps,
      options,
    });
    return extended;
  };

const mergeFunctionsOrTakeRight = (a1: any, a2: any): any => {
  if (typeof a1 === 'function' && typeof a2 === 'function') {
    return (...args: any[]) => {
      a1(...args);
      a2(...args);
    };
  }
  return a2;
};

export function mergeOptions<T>(options: Partial<T>, override: T): T {
  return R.mergeWith(mergeFunctionsOrTakeRight, options, override);
}

export type ExtractApiQueryNoParamsHookData<T> = T extends ApiQueryNoParamsHook<infer Result>
  ? Result
  : never;

export type ExtractApiQueryHookData<T> = T extends ApiQueryHook<any, infer Result> ? Result : never;
