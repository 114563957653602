import { useAllConversationsQuery, useCountArchivedConversationsQuery } from '@/api/chat/chat.api';
import { useChatBoxState } from '@/hooks/useChatBoxState';
import { AllConversationsWindowParams } from '@/reducers/chatReducer';
import { useEffect } from 'react';
import { MdOutlineArchive } from 'react-icons/md';
import ChatLayout from '../../ChatLayout';
import ConversationItem from './ConversationItem';

const AllConversations = ({
  windowParams,
  setArchived,
}: {
  windowParams: AllConversationsWindowParams;
  setArchived: (archived: boolean) => void;
}) => {
  const archived = windowParams.archived ?? false;
  const { data: allConversations, isLoading } = useAllConversationsQuery({
    archived,
  });
  const { setConversationsWithUser, setAllConversations } = useChatBoxState();
  const { data: archivedCount } = useCountArchivedConversationsQuery();

  useEffect(() => {
    if (archived && !archivedCount) {
      setAllConversations(false);
      setArchived(false);
    }
  }, [archived, archivedCount]);

  return (
    <ChatLayout windowTitle="Conversations" archived={archived} loading={isLoading}>
      {!!archivedCount && !archived && (
        <div
          className="bg-gray-400 text-white p-2 mb-2 cursor-pointer"
          onClick={() => {
            setArchived(true);
            setAllConversations(true);
          }}
        >
          <div className="flex flex-row justify-between items-center">
            <span>Archived conversations: {archivedCount} </span>
            <MdOutlineArchive />
          </div>
        </div>
      )}
      <div className="flex flex-col gap-y-2 overflow-auto">
        {allConversations && !!allConversations.length ? (
          allConversations.map((item, index) => (
            <ConversationItem
              onClick={() => {
                setConversationsWithUser({ otherUser: item, archived });
              }}
              key={index}
              conversationItem={item}
              archived={windowParams.archived ?? false}
            />
          ))
        ) : (
          <p className="italic text-center">No conversations</p>
        )}
      </div>
    </ChatLayout>
  );
};

export default AllConversations;
