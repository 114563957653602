import {
  useAllConversationsWithUserQuery,
  useMarkMessagesAsReadMutation,
  useSendUnthreadedMessageMutation,
  useUnthreadedChatMessagesQuery,
} from '@/api/chat/chat.api';
import { ChatMessageDTO } from '@/api/chat/chat.dto';
import { useCurrentUserId } from '@/features/user/currentUser.service';
import { useApiWebSocket } from '@/hooks/useApiWebSocket';
import { useChatBoxState } from '@/hooks/useChatBoxState';
import { ConversationsWithUserWindowParams } from '@/reducers/chatReducer';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { AiOutlineSend } from 'react-icons/ai';
import { MdOutlinePlayDisabled } from 'react-icons/md';
import ChatLayout from '../../ChatLayout';
import { useConversationState } from '../Conversation/Conversation.state';
import { MessageItem } from '../Conversation/MessageItem';
import ConversationsWithUserItem from './ConversationsWithUserItem';

const ConversationsWithUser = ({
  windowParams,
}: {
  windowParams: ConversationsWithUserWindowParams;
}) => {
  const { setConversation } = useChatBoxState();
  const { data: conversations, isLoading: isConversationsLoading } =
    useAllConversationsWithUserQuery({
      otherUserId: windowParams.otherUser.userId,
      archived: windowParams.archived,
    });
  const { data: initialMessages, isLoading: isMessagesLoading } = useUnthreadedChatMessagesQuery(
    windowParams.otherUser.userId,
  );
  const [chatInput, setChatInput] = useState<string>('');
  const messagesListRef = useRef<HTMLDivElement>(null);
  const markMessagesAsReadMutation = useMarkMessagesAsReadMutation();
  const { messages, appendMessage } = useConversationState({
    initialMessages: initialMessages ?? [],
  });

  const currentUserId = useCurrentUserId();

  const [unthreadedConversationId, setUnthreadedConversationId] = useState<number>();

  const markMessagesAsRead = (messages: ChatMessageDTO[]) => {
    const unreadIds = messages
      .filter((message) => message.readAt === null && message.sender.id !== currentUserId)
      .map((item) => item.id);
    if (unreadIds && unreadIds.length > 0 && unthreadedConversationId) {
      markMessagesAsReadMutation.mutate({
        conversationId: unthreadedConversationId,
        ids: unreadIds,
      });
    }
  };

  useEffect(() => {
    if (messages.length) {
      setUnthreadedConversationId(messages[0].conversationId);
      markMessagesAsRead(messages);
    }
  }, [messages]);

  const scrollToBottom = () => {
    if (messagesListRef.current) {
      const scrollHeight = messagesListRef.current.scrollHeight;
      const height = messagesListRef.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      messagesListRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };
  const sendNewMessage = useSendUnthreadedMessageMutation({
    onSuccess(data) {
      appendMessage(data);
      setChatInput('');
      scrollToBottom();
    },
  });

  function submitNewMessage(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (chatInput.length === 0) return;
    if (sendNewMessage.isLoading) return;
    sendNewMessage.mutate({ message: chatInput, otherUserId: windowParams.otherUser.userId });
  }

  useApiWebSocket({
    onMessage(msg) {
      if (msg.type !== 'chatMessage') return;
      if (msg.message.conversationId !== unthreadedConversationId) return;
      appendMessage(msg.message);
      scrollToBottom();
    },
  });

  return (
    <ChatLayout
      windowTitle={`Chat with ${windowParams.otherUser.userName}`}
      title="Threads:"
      loading={isConversationsLoading || isMessagesLoading}
      archived={windowParams.archived}
    >
      <div className="h-full flex flex-col justify-between gap-y-2">
        <div ref={messagesListRef} className="flex flex-col gap-y-2 overflow-auto h-full">
          {conversations &&
            conversations.map((item, index) => (
              <ConversationsWithUserItem
                onClick={() =>
                  setConversation({
                    conversation: item,
                    otherUser: windowParams.otherUser,
                    archived: windowParams.archived,
                  })
                }
                key={index}
                conversationItem={item}
                archived={windowParams.archived}
              />
            ))}
          <div>
            {!windowParams.archived && !!messages.length && !!currentUserId && (
              <>
                <span className="mt-2">Unthreaded Messages:</span>
                {messages.map((item, index) => (
                  <MessageItem key={index} message={item} currentUserId={currentUserId} />
                ))}
              </>
            )}
          </div>
        </div>

        {!windowParams.archived && (
          <form className="w-full border-t border-gray-cb pb-5 md:pb-0" onSubmit={submitNewMessage}>
            <div className="w-full p-2 flex flex-row items-center">
              <input
                value={chatInput}
                onChange={(value) => setChatInput(value.target.value)}
                className="w-full p-2 rounded-full border border-gray-cb"
                placeholder={`Message ${windowParams.otherUser.userName}`}
              />
              <button
                disabled={sendNewMessage.isLoading}
                type="submit"
                className="rounded-full mx-2 bg-primary p-3 text-white"
              >
                {sendNewMessage.isLoading ? (
                  <MdOutlinePlayDisabled size={30} />
                ) : (
                  <AiOutlineSend size={30} />
                )}
              </button>
            </div>
          </form>
        )}
      </div>
    </ChatLayout>
  );
};

export default ConversationsWithUser;
