import { MembershipLevelName } from '@/features/membership/membership.api';
import {
  getCurrentUserPersistentData,
  useIsAuthenticated,
} from '@/features/user/currentUser.service';
import {
  UserDto,
  UserFullDto,
  UserShowcaseSearchDto,
  UserUpdateDto,
  UserWithFollowingDto,
} from '@/types';
import {
  ApiQueryOptions,
  buildApiMutationHook,
  buildApiQueryHook,
  buildApiQueryHookFull,
  buildApiQueryNoParamsHook,
  buildApiQueryNoParamsHookFull,
} from '@/utils/api';
import { queryClient } from '@/utils/queryClient';
import { z } from 'zod';
import axiosI from '../base.api';

export const resetCurrentUserCache = () => {
  const userId = getCurrentUserPersistentData()?.id;
  if (userId) {
    queryClient.resetQueries('currentUser');
    queryClient.resetQueries('userMustProvideAddress');
    queryClient.resetQueries('current_ebay_import');
  }
};

export const invalidateCurrentUserCache = () => {
  const userId = getCurrentUserPersistentData()?.id;
  if (userId) {
    queryClient.invalidateQueries('currentUser');
    queryClient.invalidateQueries('userMustProvideAddress');
    queryClient.invalidateQueries('current_ebay_import');
  }
};

export const { keyCurrentUserDataQuery, setDataForCurrentUserDataQuery, useCurrentUserDataQuery } =
  buildApiQueryNoParamsHookFull('CurrentUserDataQuery')<UserFullDto>('currentUser', () =>
    axiosI.get(`/login/getCurrentUserData`),
  );

export function getUserQueryKey({ userId }: { userId: number }) {
  return ['user', userId];
}

export const { keyOtherUserDataQuery, setDataForOtherUserDataQuery, useOtherUserDataQuery } =
  buildApiQueryHookFull('OtherUserDataQuery')<number, UserWithFollowingDto>(
    (userId) => getUserQueryKey({ userId }),
    (id) => axiosI.get(`/login/getOtherUserData/${id}`),
    (raw) => raw,
  );

export const useOptionalCurrentUserDataQuery = (options?: ApiQueryOptions<UserFullDto>) => {
  const isAuthenticated = useIsAuthenticated();

  return useCurrentUserDataQuery({
    ...options,
    enabled: (options?.enabled ?? true) && isAuthenticated,
  });
};

export type UserDataResult =
  | {
      isCurrentUser: true;
      user: UserFullDto & { following?: boolean; followersCount: number };
    }
  | {
      isCurrentUser: false;
      user: UserWithFollowingDto;
    };

export const UsersOrderByEnum = z.enum(['itemsCount', 'rating', 'followersCount']);
export type UsersOrderByEnum = z.infer<typeof UsersOrderByEnum>;

export type UsersListParams = {
  limit?: number;
  offset?: number;
  search?: string;
  sameCity?: boolean;
  secureMembers?: boolean;
  orderBy?: UsersOrderByEnum;
  membershipLevel?: MembershipLevelName;
  isMediator?: boolean;
  mySaves?: boolean;
};

export const useUsers = buildApiQueryHook<UsersListParams, UserShowcaseSearchDto[]>(
  (params) => ['users', params],
  (params) =>
    axiosI.get<{ data: UserShowcaseSearchDto[]; params: UsersListParams }>('/users', {
      params,
    }),
);

export const useUsersCount = buildApiQueryHook<UsersListParams, number>(
  (params) => ['users', { ...params, count: true }],
  (params) => axiosI.get<{ data: number }>('/users/count', { params }),
);

export type UsersEmailVerifyConfirmParams = {
  otp: number;
};

export type UsersEmailVerifyConfirmResult = {
  email: string;
  isEmailVerified: boolean;
};

export const useUsersEmailVerifyConfirm = buildApiMutationHook<
  UsersEmailVerifyConfirmParams,
  UsersEmailVerifyConfirmResult
>((data) => axiosI.post('/users/email/verify/confirm', data));

export type UserSendEmailVerification = {
  email: string;
};

export const useUserSendEmailVerificationMutation = buildApiMutationHook<
  void,
  UserSendEmailVerification
>((data) => axiosI.post('/users/email/verify/send'));

export type UserSendPhoneVerification = {
  phoneNo: string;
};

export const useUserSendPhoneVerificationMutation = buildApiMutationHook<
  void,
  UserSendPhoneVerification
>(() => axiosI.post('/users/phoneNo/verify/send'));

export type UsersPhoneVerifyConfirmParams = {
  otp: number;
};

export type UsersPhoneVerifyConfirmResult = {
  phoneNo: string;
  isPhoneVerified: boolean;
};

export const useUsersPhoneVerifyConfirm = buildApiMutationHook<
  UsersPhoneVerifyConfirmParams,
  UsersPhoneVerifyConfirmResult
>((data) => axiosI.post('/users/phoneNo/verify/confirm', data));

type UsersMustProvideAddressResult = {
  mustProvideAddress: boolean;
};

type UsersMustVerifyEmailPhoneResult = {
  mustVerifyEmail: boolean;
  mustVerifyPhone: boolean;
};

export const useUsersMustProvideAddressQuery =
  buildApiQueryNoParamsHook<UsersMustProvideAddressResult>('userMustProvideAddress', () =>
    axiosI.get('/users/must-provide-address'),
  );

export const useUsersMustVerifyEmailPhoneQuery =
  buildApiQueryNoParamsHook<UsersMustVerifyEmailPhoneResult>('userMustVerifyEmailPhone', () =>
    axiosI.get('/users/must-verify-email-phone'),
  );

export const useCloseUserAccountMutation = buildApiMutationHook<void, void>(
  () => axiosI.post(`/users/closeAccount`),
  (options) => ({
    ...options,
    onSuccess(...args) {
      invalidateCurrentUserCache();
      options?.onSuccess?.(...args);
    },
  }),
);

export const useUserEditMutation = buildApiMutationHook<UserUpdateDto, UserDto>(
  (data) => axiosI.put('/users/', data),
  (options) => ({
    ...options,
    onSuccess(...args) {
      invalidateCurrentUserCache();
      options?.onSuccess?.(...args);
    },
  }),
);
