/**
 * @example
 * dropKeysWithUndefinedValues({ 'qwer': undefined, 'asdf': 'hello world' });
 * // { 'asdf': 'hello world' }
 */
export function dropKeysWithUndefinedValues<V>(
  obj: Record<string, V | undefined>,
): Record<string, V> {
  return Object.entries(obj).reduce((acc, [k, v]) => {
    if (v !== undefined) {
      acc[k] = v;
    }
    return acc;
  }, <Record<string, V>>{});
}
