import 'antd/dist/antd.css';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { Suspense, useEffect, useState } from 'react';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import '../App.scss';
import { Loader } from '../components';
import { UserDataGuard } from '../components/@next/AuthedGuard/UserDataGuard';
import Chat from '../components/@next/Chat/Chat';
import '../styles/common.scss';
import { queryClient } from '../utils/queryClient';
import { ReactlazyBy } from '../utils/ReactlazyBy';

function addMobileClassIfMobile() {
  const device = deviceDetect();
  if (device.isMobile() || window.innerWidth < 768) {
    document.body.classList.add('mobile');
  } else {
    // corresponds to tailwind md: prefix
    document.body.classList.remove('mobile');
  }
}

const getDeviceDetect = (userAgent: string) => {
  const isAndroid = (): boolean => Boolean(userAgent.match(/Android/i));
  const isIos = (): boolean => Boolean(userAgent.match(/iPhone|iPad|iPod/i));
  const isOpera = (): boolean => Boolean(userAgent.match(/Opera Mini/i));
  const isWindows = (): boolean => Boolean(userAgent.match(/IEMobile/i));
  const isSSR = (): boolean => Boolean(userAgent.match(/SSR/i));

  const isMobile = (): boolean => Boolean(isAndroid() || isIos() || isOpera() || isWindows());
  const isDesktop = (): boolean => Boolean(!isMobile() && !isSSR());
  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos,
    isSSR,
  };
};
const deviceDetect = () => {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  return getDeviceDetect(userAgent);
};

if (typeof window !== 'undefined') {
  addMobileClassIfMobile();

  window.addEventListener('resize', addMobileClassIfMobile);
}

// Have to use lazy loading because of circular dependencies in the store.
const ApiWebSocketProvider = ReactlazyBy(
  () => import('../hooks/useApiWebSocket'),
  (m) => m.ApiWebSocketProvider,
);
const NotificationManager = ReactlazyBy(
  () => import('../components/@next/NotificationManager/NotificationManager'),
  (m) => m.NotificationManager,
);
const ModalManagerProvider = ReactlazyBy(
  () => import('../hooks/useModalManager'),
  (m) => m.ModalManagerProvider,
);

const tagManagerArgs: TagManagerArgs = {
  gtmId: `${process.env.NEXT_PUBLIC_GTM_ID}`,
};

export const GTM_ENABLED = process.env.NEXT_PUBLIC_GTM_ENABLED === 'true';
export const FB_PIXEL_ENABLED = process.env.NEXT_PUBLIC_FB_PIXEL_ENABLED === 'true';
export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FB_PIXEL_ID ?? '';

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
  // TODO: the problem is that store initialization has some circular dependencies, so
  // directly importing it is not possible and causes errors.
  let [store, setStore] = useState<any>();
  const router = useRouter();
  useEffect(() => {
    import('../utils/store').then((m) => {
      setStore(m.default);
    });
    if (GTM_ENABLED) {
      TagManager.initialize(tagManagerArgs);
    }
    if (FB_PIXEL_ENABLED) {
      import('react-facebook-pixel')
        .then((x) => x.default)
        .then((ReactPixel) => {
          ReactPixel.init(FB_PIXEL_ID);
          ReactPixel.pageView();
        });
    }
  }, []);

  useEffect(() => {
    if (FB_PIXEL_ENABLED) {
      import('react-facebook-pixel')
        .then((x) => x.default)
        .then((ReactPixel) => {
          ReactPixel.pageView();
          router.events.on('routeChangeComplete', () => {
            ReactPixel.pageView();
          });
        });
    }
  }, [router.events]);

  if (!store) return null;
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0, shrink-to-fit=no"
        />
        <title>Secure Trade Club</title>
      </Head>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<Loader />}>
            <ModalManagerProvider>
              <ApiWebSocketProvider>
                <NotificationManager />
                <UserDataGuard fallback={() => null} render={() => <Chat />} />

                <Component {...pageProps} />

                <ReactQueryDevtools initialIsOpen={false} />
              </ApiWebSocketProvider>
            </ModalManagerProvider>
          </Suspense>
        </QueryClientProvider>
      </Provider>
    </>
  );
};

export default App;
