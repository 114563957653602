import React from 'react';

export type LoaderProps = {
  message?: React.ReactNode;
};

const Loader = ({ message }: LoaderProps) => {
  return (
    <React.Fragment>
      <div className="overlay" />
      <div className="loader">
        <div className="loader-text">
          <img src="/images/ring-spinner.gif" alt="loader" />
          {message && <p className="loader-text">{message}</p>}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Loader;
