import { useEffect, useState } from 'react';

const localStorageEventName = 'local-storage';

type Value<T> = T | null;

// useReadLocalStorage taken from: https://usehooks-ts.com/react-hook/use-read-local-storage
// using hook directly from the library didn't react to updates and this is somehow related to useEventListener

// Get from local storage then
// parse stored json or return initialValue
const readValue = <T>(key: string): Value<T> => {
  // Prevent build error "window is undefined" but keep keep working
  if (typeof window === 'undefined') {
    return null;
  }

  try {
    const item = window.localStorage.getItem(key);
    return item ? (JSON.parse(item) as T) : null;
  } catch (error) {
    console.warn(`Error reading localStorage key “${key}”:`, error);
    return null;
  }
};

export function useReadLocalStorage<T>(key: string): Value<T> {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<Value<T>>(() => readValue(key));

  // Listen if localStorage changes
  useEffect(() => {
    setStoredValue(readValue(key));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const handleStorageChange = () => {
      setStoredValue(readValue(key));
    };

    // this only works for other documents, not the current one
    window.addEventListener('storage', handleStorageChange);

    // this is a custom event, triggered in writeValueToLocalStorage
    // See: useLocalStorage()
    window.addEventListener(localStorageEventName, handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener(localStorageEventName, handleStorageChange);
    };
  }, [key]);

  return storedValue;
}

/**
 * Using custom event "local-storage" so that local storage updates can be
 * picked up by that hook https://usehooks-ts.com/react-hook/use-local-storage.
 */
export const deleteFromStorage = (key: string) => {
  // Prevent build error "window is undefined" but keeps working
  if (typeof window == 'undefined') {
    console.error(
      `Tried deleting localStorage key “${key}” even though environment is not a client.`,
    );
    return;
  }

  try {
    window.localStorage.removeItem(key);

    // We dispatch a custom event so every useLocalStorage hook are notified
    window.dispatchEvent(new CustomEvent(localStorageEventName));
  } catch (error) {
    console.warn(`Error setting localStorage key “${key}”:`, error);
  }
};

/**
 * Using custom event "local-storage" so that local storage updates can be
 * picked up by that hook https://usehooks-ts.com/react-hook/use-local-storage.
 */
export const writeStorage = (key: string, value: unknown) => {
  // Prevent build error "window is undefined" but keeps working
  if (typeof window == 'undefined') {
    console.error(
      `Tried setting localStorage key “${key}” even though environment is not a client.`,
    );
    return;
  }

  try {
    // Save to local storage
    window.localStorage.setItem(key, JSON.stringify(value));

    // We dispatch a custom event so every useLocalStorage hook are notified
    window.dispatchEvent(new CustomEvent(localStorageEventName));
  } catch (error) {
    console.warn(`Error setting localStorage key “${key}”:`, error);
  }
};

export const clearStorage = () => {
  // Prevent build error "window is undefined" but keeps working
  if (typeof window == 'undefined') {
    console.error(`Tried clearing localStorage even though environment is not a client.`);
    return;
  }

  try {
    window.localStorage.clear();

    // We dispatch a custom event so every useLocalStorage hook are notified
    window.dispatchEvent(new CustomEvent(localStorageEventName));
  } catch (error) {
    console.warn(`Error clearing localStorage:`, error);
  }
};
