import { useState } from 'react';

export type UseModalStateResult = ReturnType<typeof useModalState>;

export const useModalState = (
  initialShowModal: boolean = false,
): {
  showModal: boolean;
  set: (showModal: boolean) => void;
  open: () => void;
  close: () => void;
  closeStart: () => void;
  closeFinish: () => void;
} => {
  const [showModal, setShowModal] = useState(initialShowModal);
  const set = (showModal: boolean) => setShowModal(showModal);
  const open = () => setShowModal(true);
  const close = () => setShowModal(false);
  const closeStart = () => setShowModal(false);
  const closeFinish = () => setShowModal(false);

  return { showModal, set, open, close, closeStart, closeFinish };
};
