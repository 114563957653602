import { getCurrentUserPersistentData } from '@/features/user/currentUser.service';
import { message } from 'antd';
import axios from 'axios';
import Router from 'next/router';
import { getCurrentAdminToken } from '../features/admin/currentAdminUser.service';
import routes from '../routes/routes';
import { ApiError, handleApiError } from '../utils/api';

export const showMessageOnError = (error: ApiError) => {
  message.error(error.message);
};

export const showMessageOnErrorConfig = {
  onError: showMessageOnError,
};

const url = process.env.NEXT_PUBLIC_API_URL;
if (!url) throw new Error(`NEXT_PUBLIC_API_URL must be set`);

export const apiUrl = (pathname?: string, params?: Record<string, string>) => {
  let result = url;
  if (pathname) {
    result += pathname;
  }
  if (params) {
    const keyValues = [];
    for (const key in params) {
      keyValues.push(`${key}=${encodeURIComponent(params[key])}`);
    }
    result += '?' + keyValues.join('&');
  }
  return result;
};

const axiosI = axios.create({
  baseURL: url,
});

// to save some time when you copy-pasted url from api and suddenly it tries to fetch /api/api
const unPrefixPath = (path: string) => {
  return path.startsWith('/api') ? path.slice(4) : path;
};

const { get, post, patch, delete: del } = axiosI;
axiosI.get = ((path, ...args) => get(unPrefixPath(path), ...args)) as typeof get;
axiosI.post = ((path, ...args) => post(unPrefixPath(path), ...args)) as typeof get;
axiosI.patch = ((path, ...args) => patch(unPrefixPath(path), ...args)) as typeof get;
axiosI.delete = ((path, ...args) => del(unPrefixPath(path), ...args)) as typeof get;

axiosI.interceptors.request.use(
  (config) => {
    const userData = getCurrentUserPersistentData();
    const adminToken = getCurrentAdminToken();
    if (userData || adminToken) {
      return {
        ...config,
        headers: {
          ...config.headers,
          authorization: userData?.accessToken,
          'x-admin-token': adminToken,
        },
      };
    }
    return config;
  },
  (err) => Promise.reject(err),
);

const redirectOnError = (error: unknown) => {
  const apiError = handleApiError(error);
  switch (apiError.detail.errorCode) {
    case 'membership-upgrade-required':
      Router.push(routes.upgradeMembership);
      break;
    case 'connected-account-required':
    case 'connected-account-failure':
      Router.push(routes.setupStripeAccount);
      break;
    case 'address-required': {
      const userData = getCurrentUserPersistentData();
      if (userData) {
        Router.push(routes.profile(userData.id));
      }
      break;
    }
    default:
      break;
  }
  throw error;
};

axiosI.interceptors.response.use(undefined, redirectOnError);

export default axiosI;
