import jwtDecode, { JwtPayload } from 'jwt-decode';
import { AdminLoginResponse, AdminSchema } from '../../api/admin.api';
import { deleteFromStorage, useReadLocalStorage, writeStorage } from '../../utils/localStorage';

const storageKey = 'adminData';

export function getCurrentAdminData(): AdminLoginResponse | undefined {
  const storedJSON = localStorage.getItem(storageKey);
  if (storedJSON === null) return;

  try {
    const adminData: AdminLoginResponse = JSON.parse(storedJSON);
    const { accessToken } = adminData;
    const decoded: JwtPayload & { adminId?: unknown } = jwtDecode(accessToken);
    if (typeof decoded.adminId === 'number') {
      const isTokenValid = (decoded.exp && decoded.exp * 1000 > new Date().getTime()) || false;
      if (isTokenValid) {
        const admin = AdminSchema.parse(adminData.admin);
        return { accessToken, admin };
      }
    }
  } catch (_) {}

  deleteFromStorage(storageKey);
  return;
}

export const getCurrentAdminToken = () => getCurrentAdminData()?.accessToken;

export const setCurrentAdminData = (adminData: AdminLoginResponse | null) =>
  adminData ? writeStorage(storageKey, adminData) : deleteFromStorage(storageKey);

export const useCurrentAdminData = () => {
  const admin = useReadLocalStorage<AdminLoginResponse>(storageKey);
  return admin;
};

if (typeof window !== 'undefined') {
  getCurrentAdminData();
}
