import { FormEvent, useState } from 'react';
import { AiOutlineSend } from 'react-icons/ai';
import { MdOutlinePlayDisabled } from 'react-icons/md';
import {
  useSendMessageMutation,
  useStartNewConversationMutation,
} from '../../../../../api/chat/chat.api';
import { useChatBoxState } from '../../../../../hooks/useChatBoxState';
import { StartConversationWindowParams } from '../../../../../reducers/chatReducer';
import routes from '../../../../../routes/routes';
import ChatLayout from '../../ChatLayout';

const StartConversation = ({ windowParams }: { windowParams: StartConversationWindowParams }) => {
  const { setConversation } = useChatBoxState();
  const [chatInput, setChatInput] = useState<string>('');

  const sendNewMessage = useSendMessageMutation();
  const startNewConversation = useStartNewConversationMutation({
    onSuccess(data) {
      sendNewMessage
        .mutateAsync({ message: chatInput, conversationId: data.id })
        .then(() => setConversation({ conversation: data, otherUser: windowParams.otherUser }));
    },
  });
  function startConversation(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (chatInput.length === 0) return;
    startNewConversation.mutate({
      showcaseItemId: windowParams.tempConversation.showcaseItemId,
      tradeOfferId: windowParams.tempConversation.tradeOfferId,
    });
  }

  const itemLink = windowParams.tempConversation.showcaseItemId
    ? routes.showcaseItem({
        userId: windowParams.otherUser.userId,
        id: windowParams.tempConversation.showcaseItemId,
      })
    : routes.tradeView(windowParams.tempConversation.tradeOfferId!);

  return (
    <ChatLayout
      windowTitle={`Chat with ${windowParams.otherUser.userName} about:`}
      subTitle={windowParams.tempConversation.subject}
      title="Messages:"
      loading={false}
      linkTo={itemLink}
    >
      <div className="h-full flex flex-col justify-between gap-y-2">
        <div className="flex flex-col gap-y-2 overflow-auto h-full">
          <p className="flex h-full justify-center items-center font-bold text-xl">
            Start Messaging
          </p>
        </div>

        <form className="w-full border-t border-gray-cb pb-5 md:pb-0" onSubmit={startConversation}>
          <div className="w-full p-2 flex flex-row items-center">
            <input
              value={chatInput}
              onChange={(value) => setChatInput(value.target.value)}
              className="w-full p-2 rounded-full border border-gray-cb"
              placeholder={`Message ${windowParams.otherUser.userName}`}
            />
            <button
              disabled={startNewConversation.isLoading || sendNewMessage.isLoading}
              type="submit"
              className="rounded-full mx-2 bg-primary p-3 text-white"
            >
              {startNewConversation.isLoading || sendNewMessage.isLoading ? (
                <MdOutlinePlayDisabled size={30} />
              ) : (
                <AiOutlineSend size={30} />
              )}
            </button>
          </div>
        </form>
      </div>
    </ChatLayout>
  );
};

export default StartConversation;
