import { Avatar } from 'antd';
import { ChatMessageDTO } from '../../../../../api/chat/chat.dto';
import { formatDateTime } from '../../../../../utils/datetime';
import { getProfilePhoto } from '../../../../../utils/profilePhoto';

export type ChatItemProps = {
  message: ChatMessageDTO;
  currentUserId: number;
};

export const MessageItem = ({ message, currentUserId }: ChatItemProps) => {
  if (message.sender.id === currentUserId) {
    return (
      <div
        id={`__chat_item_${message.id}`}
        className="w-full flex flex-row justify-start item-center mb-4"
      >
        <Avatar
          size="large"
          src={getProfilePhoto(message.sender, 'medium')}
          alt="profilePhoto"
          className="mt-4"
        />
        <div>
          <div className="min-w-[200px] flex flex-col justify-center items-start right-0 p-2 my-2 ml-2 rounded-2xl border bg-white border-primary text-black">
            <div className="flex flex-col w-full justify-start mb-px"></div>
            <span className="text-xs mb-px">You:</span>
            <span className="text-sm">{message.message} </span>
          </div>
          <span className="flex flex-row w-full justify-start text-xs pl-4 text-gray-79">
            {formatDateTime(message.date)}
          </span>
        </div>
      </div>
    );
  }
  return (
    <div
      id={`__chat_item_${message.id}`}
      className="w-full flex flex-row justify-end item-center mb-4"
    >
      <div>
        <div className="min-w-[200px] left-0 p-2 my-2 mr-2 rounded-2xl bg-primary border-white text-white">
          <div className="flex flex-col w-full justify-start mb-px">
            <span className="text-xs mb-px">{message.sender.userName}:</span>
            <span className="text-sm">{message.message}</span>
          </div>
        </div>
        <span className="flex flex-row w-full justify-end text-xs pr-4 text-gray-79">
          {formatDateTime(message.date)}
        </span>
      </div>
      <Avatar
        size="large"
        src={getProfilePhoto(message.sender, 'medium')}
        alt="profilePhoto"
        className="mt-4"
      />
    </div>
  );
};
