import { useCurrentUserId } from '@/features/user/currentUser.service';
import routes from '@/routes/routes';
import { getCurrentPath } from '@/utils/url';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useAllUnreadMessagesQuery, useFindConversationsQuery } from '../../../api/chat/chat.api';
import { useOtherUserDataQuery } from '../../../api/user/user.api';
import { useChatBoxState } from '../../../hooks/useChatBoxState';
import styles from './chat.module.scss';
import ChatBoxButton from './ChatBoxButton';
import ChatConversations from './ChatConversations/ChatConversations';

const Chat = () => {
  const {
    isChatBoxOpen,
    isTransforming,
    finishTransforming,
    setConversation,
    setConversationsWithUser,
    setStartConversation,
    setAllConversations,
  } = useChatBoxState();
  const currentPath = getCurrentPath();
  const router = useRouter();
  const [userId, setUserId] = useState<number>();
  const currentUserId = useCurrentUserId();
  const [showcaseItemId, setShowcaseItemId] = useState<number>();
  const [tradeOfferId, setTradeOfferId] = useState<number>();
  const { data: unreadCount } = useAllUnreadMessagesQuery();
  const { data: otherUser } = useOtherUserDataQuery(userId ?? 0, {
    enabled: userId !== undefined,
  });
  const matchHiddenRoute =
    routes.setupAccount() === currentPath ||
    routes.tradeView('[tradeOfferId]') === router.pathname ||
    routes.ebay.root('[userId]') === router.pathname ||
    routes.ebay.edit('[userId]', '[id]') === router.pathname ||
    (currentPath.includes('/live') && !currentPath.includes('showcase'));
  const { data: foundConversation } = useFindConversationsQuery(
    {
      showcaseItemId,
      tradeOfferId,
    },
    {
      enabled:
        currentUserId !== undefined &&
        (!!showcaseItemId || !!tradeOfferId) &&
        userId !== currentUserId,
    },
  );

  useEffect(() => {
    if (currentPath) {
      const paths = currentPath.split('/');
      if (paths.length > 1) {
        if (paths[1] === 'user' && paths[2] && !isNaN(parseInt(paths[2]))) {
          setUserId(parseInt(paths[2]));
          setShowcaseItemId(undefined);
          setTradeOfferId(undefined);
        }
        if (currentPath.includes('/showcase/item/')) {
          setShowcaseItemId(parseInt(paths[paths.length - 1]));
          setTradeOfferId(undefined);
        }
        if (currentPath.includes('/trade/view/')) {
          setTradeOfferId(parseInt(paths[paths.length - 1]));
          setShowcaseItemId(undefined);
        }
        if (
          !currentPath.includes('/showcase/item/') &&
          !currentPath.includes('/trade/view/') &&
          paths[1] !== 'user'
        ) {
          setAllConversations();
        }
      } else {
        setAllConversations();
      }
    }
  }, [currentPath]);

  useEffect(() => {
    if (otherUser && !foundConversation) {
      if (otherUser.id !== currentUserId) {
        setConversationsWithUser({
          otherUser: { ...otherUser, userId: otherUser.id, userName: otherUser.userName },
        });
      }
    } else if (foundConversation) {
      setUserId(foundConversation.otherUserId);
      if (!otherUser) return;
      if (!('id' in foundConversation.conversation)) {
        setStartConversation({
          otherUser: { ...otherUser, userId: otherUser.id, userName: otherUser.userName },
          tempConversation: foundConversation.conversation,
        });
      } else {
        setConversation({
          otherUser: { ...otherUser, userId: otherUser.id, userName: otherUser.userName },
          conversation: foundConversation.conversation,
        });
      }
    }
  }, [foundConversation, otherUser]);

  if (!currentUserId || matchHiddenRoute) return null;

  return (
    <div
      className={` ${styles.container} ${
        isChatBoxOpen ? styles['opened-chat-box'] : styles['closed-chat-box']
      } ${isTransforming && 'bg-primary'}`}
      onTransitionEnd={(e) => {
        e.propertyName === 'width' && finishTransforming();
      }}
    >
      {!isTransforming &&
        (!isChatBoxOpen ? <ChatBoxButton unreadCount={unreadCount} /> : <ChatConversations />)}
    </div>
  );
};

export default Chat;
