import routes from '@/routes/routes';
import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { useChatBoxState } from '../../../hooks/useChatBoxState';
import { useIsMobile } from '../../../hooks/useIsMobile';
import StandaloneLoader from '../StandaloneLoader';
type Props = {
  windowTitle: string;
  title?: string;
  subTitle?: string;
  children?: ReactNode;
  loading?: boolean;
  linkTo?: string;
  archived?: boolean;
};

const ChatLayout = ({
  windowTitle,
  title,
  subTitle,
  children,
  loading,
  linkTo,
  archived,
}: Props) => {
  const router = useRouter();
  const showHeader = routes.tradeView('[tradeOfferId]') !== router.pathname;
  const { closeChatBox } = useChatBoxState();
  const isMobile = useIsMobile();
  return (
    <div className="w-full h-full flex flex-col">
      {showHeader && (
        <div
          className={cn('flex flex-col p-2 text-white', archived ? 'bg-gray-500' : 'bg-primary')}
        >
          <span className="ml-7">
            {archived ? 'Archived ' : ''}
            {windowTitle}
          </span>
          {subTitle && (
            <div className="w-[95%] truncate">
              {linkTo ? (
                <Link href={linkTo}>
                  <a onClick={() => isMobile && closeChatBox()}>
                    <span className="ml-7">{subTitle}</span>
                  </a>
                </Link>
              ) : (
                <span className="ml-7">{subTitle}</span>
              )}
            </div>
          )}
        </div>
      )}
      <div className="p-2 flex flex-col w-full h-[90vh] md:h-[550px]  gap-y-2">
        {showHeader && <span>{title}</span>}

        {loading ? (
          <div className="flex items-center justify-center h-full">
            <StandaloneLoader />
          </div>
        ) : (
          <div className="w-full h-full py-1.5 overflow-auto">{children}</div>
        )}
      </div>
    </div>
  );
};

export default ChatLayout;
