import dayjs from 'dayjs';
import 'dayjs/locale/en';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(duration);
dayjs.extend(relativeTime);

export const DATE_FORMAT = 'MM/DD/YY';
export const TIME_FORMAT = 'HH:mm';
export const DATETIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}` as const;

export const toFormattedTime = (time: string) => {
  return dayjs(time).format(TIME_FORMAT);
};

export const toFormattedDate = (time: string) => {
  return dayjs(time).format(DATE_FORMAT);
};

export const formatDateTime = (date: Date | string) => {
  return dayjs(date).format(DATETIME_FORMAT);
};

export const formatDurationSince = (date: Date | string) => {
  const duration = dayjs().diff(date);
  return dayjs.duration(duration).humanize();
};

export const formattedDuration = (
  startDate: 'now' | string | number | dayjs.Dayjs | Date | null | undefined,
  endDate: string | number | dayjs.Dayjs | Date | null | undefined,
  format: 'short' | 'long' = 'long',
) => {
  const duration = dayjs(endDate).diff(startDate === 'now' ? undefined : startDate);
  const inShortFormat =
    dayjs.duration(duration).days() === 14
      ? '2W'
      : dayjs.duration(duration).days() > 0
      ? `${dayjs.duration(duration).days()}d`
      : dayjs.duration(duration).hours() > 0
      ? `${dayjs.duration(duration).hours()}h`
      : dayjs.duration(duration).minutes() > 0
      ? `${dayjs.duration(duration).minutes()}m`
      : dayjs.duration(duration).seconds() > 0
      ? `${dayjs.duration(duration).seconds()}s`
      : '';

  const inLongFormat = dayjs.duration(duration).humanize();

  return format === 'long' ? inLongFormat : inShortFormat;
};

export const msToHours = (ms: number) => ms / 1000 / 60 / 60;
