import { useLayoutEffect, useState } from 'react';

const checkMobileClass = () => document.body.classList.contains('mobile');

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(checkMobileClass());
  useLayoutEffect(() => {
    const check = () => setIsMobile(checkMobileClass());

    window.addEventListener('resize', check);
    return () => {
      window.removeEventListener('resize', check);
    };
  }, []);
  return isMobile;
};
